<template>
  <div class="dropdown">
    <button @click="toggleDropdown">
      <span class="no-wrap">
        <span v-if="currentLocale === 'uk'">
          {{ globalSore.selectedBasin.name_ua }}
        </span>
        <span v-else>
          {{ globalSore.selectedBasin.name_en }}
        </span>
      </span>
      <span
        :class="{
          opened: isOpen
        }"
      >
        <img src="./image/arrow-hight.png" alt="arrow" />
      </span>
    </button>

    <SubBasins v-if="isOpen" @selected-item="toggleDropdown" />
  </div>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from 'vue'
import { useGlobalStore } from '@/stores/globalStore'
import SubBasins from '@/UI/Header/components/BasinDropDown/SubBasins.vue'
import {useI18n} from "vue-i18n";
const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const isOpen = ref(false)
const blinkShow = ref(true)

const globalSore = useGlobalStore()

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

const closeDropdown = (event) => {
  if (!event.target.closest('.dropdown')) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: unset;
  width: 96.5%;
}

.dropdown {
  position: relative;
  z-index: 11;

  button {
    cursor: pointer;
    color: var(--color-black);
    font-size: 16px;
    border: var(--color-primary-blue) 1px solid;
    border-radius: 8px;
    background-color: var(--color-white);
    display: flex;
    justify-content: space-between;
    padding: 14px 11px;
    width: 520px;
    text-align: left;
    font-weight: 500;

    &:hover {
      background-color: var(--color-light-gray);
    }

    .opened img {
      transform: rotate(180deg);
    }
  }
}
</style>
