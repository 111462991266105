<template>
  <header-component />
  <main>
    <div class="container">
      <div class="headline1">
        {{ $t('dataPage.describe.climateChange') }}
        <template v-if="currentLocale === 'uk'">{{
          addItoAseinUk(globalStore.selectedBasin.name_ua)
        }}</template>
        <template v-if="currentLocale === 'en'">
          <template v-if="globalStore.selectedBasin.name_ua !== 'Всі басейни України'">the</template>
          {{
            addItoAseinEn(globalStore.selectedBasin.name_en)
          }}</template>
      </div>
      <div class="content">
        <tabs-component :tab="selectedTab" />

        <div class="data-block" ref="captureElement">
          <div class="headline2">
            {{ $t('dataPage.describe.' + selectedTab) }} {{ $t('dataPage.describe.in') }}
            {{ periodYears }}<template v-if="currentLocale === 'uk'"> рр.</template>*
          </div>

          <div class="label1 description">
            {{ $t('dataPage.describe.' + globalStore.sceneriesAtmosphere) }}
          </div>

          <div class="control-panel">
            <div class="left">
              <calendar-component v-model="periodYears" />

              <div class="display-type">
                <div
                  class="button average"
                  :class="{
                    active: selectedDisplayType === 'average'
                  }"
                  @click="selectedDisplayType = 'average'"
                >
                  <div class="inner">
                    <div class="icon">
                      <svg
                        class="default"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 14.25H6V8.25H3V14.25ZM7.5 14.25H10.5V3.75H7.5V14.25ZM12 14.25H15V9.75H12V14.25ZM1.5 15.75V6.75H6V2.25H12V8.25H16.5V15.75H1.5Z"
                          fill="#082F57"
                        />
                      </svg>

                      <svg
                        class="active"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 14.25H6V8.25H3V14.25ZM7.5 14.25H10.5V3.75H7.5V14.25ZM12 14.25H15V9.75H12V14.25ZM1.5 15.75V6.75H6V2.25H12V8.25H16.5V15.75H1.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="label1">
                      {{ $t('dataPage.period.avg') }}
                    </div>
                  </div>
                </div>

                <div
                  class="button average"
                  :class="{
                    active: selectedDisplayType === 'river_cycle'
                  }"
                  @click="selectedDisplayType = 'river_cycle'"
                >
                  <div class="inner">
                    <div class="icon">
                      <svg
                        class="default"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 15.75L4.35 11.25L2.25 12.75V10.9125L4.5 9.3L9.09375 13.7625L12.225 11.25H15.75V12.75H12.75L9 15.75ZM9 9L5.71875 5.71875L2.25 8.25V6.39375L5.86875 3.75L9.15 7.03125L15.75 2.25V4.10625L9 9Z"
                          fill="#082F57"
                        />
                      </svg>

                      <svg
                        class="active"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 15.75L4.35 11.25L2.25 12.75V10.9125L4.5 9.3L9.09375 13.7625L12.225 11.25H15.75V12.75H12.75L9 15.75ZM9 9L5.71875 5.71875L2.25 8.25V6.39375L5.86875 3.75L9.15 7.03125L15.75 2.25V4.10625L9 9Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="label1">
                      {{ $t('dataPage.period.riverCycle') }}
                    </div>
                  </div>
                </div>

                <div
                  class="button average"
                  :class="{
                    active: selectedDisplayType === 'map'
                  }"
                  @click="selectedDisplayType = 'map'"
                >
                  <div class="inner">
                    <div class="icon">
                      <svg
                        class="default"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 15.75L6.75 14.175L3.2625 15.525C3.0125 15.625 2.78125 15.5969 2.56875 15.4406C2.35625 15.2844 2.25 15.075 2.25 14.8125V4.3125C2.25 4.15 2.29688 4.00625 2.39062 3.88125C2.48438 3.75625 2.6125 3.6625 2.775 3.6L6.75 2.25L11.25 3.825L14.7375 2.475C14.9875 2.375 15.2188 2.40313 15.4313 2.55938C15.6438 2.71563 15.75 2.925 15.75 3.1875V13.6875C15.75 13.85 15.7031 13.9938 15.6094 14.1188C15.5156 14.2438 15.3875 14.3375 15.225 14.4L11.25 15.75ZM10.5 13.9125V5.1375L7.5 4.0875V12.8625L10.5 13.9125ZM12 13.9125L14.25 13.1625V4.275L12 5.1375V13.9125ZM3.75 13.725L6 12.8625V4.0875L3.75 4.8375V13.725Z"
                          fill="#082F57"
                        />
                      </svg>

                      <svg
                        class="active"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 15.75L6.75 14.175L3.2625 15.525C3.0125 15.625 2.78125 15.5969 2.56875 15.4406C2.35625 15.2844 2.25 15.075 2.25 14.8125V4.3125C2.25 4.15 2.29688 4.00625 2.39062 3.88125C2.48438 3.75625 2.6125 3.6625 2.775 3.6L6.75 2.25L11.25 3.825L14.7375 2.475C14.9875 2.375 15.2188 2.40313 15.4313 2.55938C15.6438 2.71563 15.75 2.925 15.75 3.1875V13.6875C15.75 13.85 15.7031 13.9938 15.6094 14.1188C15.5156 14.2438 15.3875 14.3375 15.225 14.4L11.25 15.75ZM10.5 13.9125V5.1375L7.5 4.0875V12.8625L10.5 13.9125ZM12 13.9125L14.25 13.1625V4.275L12 5.1375V13.9125ZM3.75 13.725L6 12.8625V4.0875L3.75 4.8375V13.725Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="label1">
                      {{ $t('dataPage.period.map') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="right">
              <period-component v-if="selectedDisplayType !== 'river_cycle'" v-model="period" />
              <compare-component v-model="compare" :selected-display-type="selectedDisplayType" />
              <download-component :season="period" :years-period="periodYears" :tab="selectedTab"  />
            </div>
          </div>

          <chart-component
            v-if="selectedDisplayType === 'average'"
            :selected-display-type="selectedDisplayType"
            :tab-selected="selectedTab"
            :period="period"
            :periodYears="periodYears"
            :compare="compare"
          />

          <chart-component-for-river-cycle
            v-if="selectedDisplayType === 'river_cycle'"
            :selected-display-type="selectedDisplayType"
            :tab-selected="selectedTab"
            :period="period"
            :periodYears="periodYears"
            :compare="compare"
          />

          <div class="map-block" v-if="selectedDisplayType === 'map'">
            <div class="maps">
              <MapBlock
                  ref="mapBlock1"
                  :period-season="period"
                  :selected-tab="selectedTab"
                  :period-years="periodYears"
                  :scenario="globalStore.sceneriesAtmosphere"
                  :selectedMod="selectedMod"
              />
              <MapBlock
                  v-if="compare === 'rcp45' || compare === 'rcp85'"
                  ref="mapBlock2"
                  :period-season="period"
                  :selected-tab="selectedTab"
                  :period-years="periodYears"
                  :scenario="globalStore.sceneriesAtmosphere === 'rcp85' ? 'rcp45' : 'rcp85'"
                  :selectedMod="selectedMod"
              />
            </div>

            <LegendComponent :tab="selectedTab" :season="period" />

            <div class="description-bottom">
              <div
                  v-html="$t('dataPage.chart.description1')"></div>
              <div v-if="selectedDisplayType === 'map'">
                <template v-if="selectedTab === 'discharge' || selectedTab === 'water_flow'">*
                  <template v-if="globalStore.selectedBasinCode !== globalStore.selectedBigBasinCode">
                    {{ $t('dataPage.chart.description2household') }}
                  </template>
                  <template v-else>
                    {{ $t('dataPage.chart.description2end') }}
                  </template>
                </template>
              </div>
            </div>
          </div>

          <div v-if="selectedDisplayType === 'map'" class="hint-map body1-bold">{{$t('dataPage.chart.selectModel')}}</div>
          <template
            v-if="globalStore.projectResearch === 'CORDEX-EUR-11' && selectedDisplayType === 'map'"
          >
            <div class="flex-table">
              <div class="flex-column">
                <div
                  class="flex-row"
                  :class="{
                    selected: selectedMod === 'Mod1_EC-EARTH_HIRHAM5'
                  }"
                  @click="selectedMod = 'Mod1_EC-EARTH_HIRHAM5'"
                >
                  <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.1</div>
                  <div class="value body1">— EC-EARTH_HIRHAM5</div>
                </div>
                <div
                  class="flex-row"
                  :class="{
                    selected: selectedMod === 'Mod5_HadGEM2-ES_RCA4'
                  }"
                  @click="selectedMod = 'Mod5_HadGEM2-ES_RCA4'"
                >
                  <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.5</div>
                  <div class="value body1">— HadGEM2-ES_RCA4</div>
                </div>
              </div>
              <div class="flex-column">
                <div
                  class="flex-row"
                  :class="{
                    selected: selectedMod === 'Mod2_EC-EARTH_RACMO22E'
                  }"
                  @click="selectedMod = 'Mod2_EC-EARTH_RACMO22E'"
                >
                  <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.2</div>
                  <div class="value body1">— EC-EARTH_RACMO22E</div>
                </div>
                <div
                  class="flex-row"
                  :class="{
                    selected: selectedMod === 'Mod6_MPI-ESM-LR_RCA4'
                  }"
                  @click="selectedMod = 'Mod6_MPI-ESM-LR_RCA4'"
                >
                  <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.6</div>
                  <div class="value body1">— MPI-ESM-LR_RCA4</div>
                </div>
              </div>
              <div class="flex-column">
                <div
                  class="flex-row"
                  :class="{
                    selected: selectedMod === 'Mod3_IPSL-CM5A-MR_RCA4'
                  }"
                  @click="selectedMod = 'Mod3_IPSL-CM5A-MR_RCA4'"
                >
                  <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.3</div>
                  <div class="value body1">— IPSL-CM5A-MR_RCA4</div>
                </div>
              </div>
              <div class="flex-column">
                <div
                  class="flex-row"
                  :class="{
                    selected: selectedMod === 'Mod4_HadGEM-ES_RACMO22E'
                  }"
                  @click="selectedMod = 'Mod4_HadGEM-ES_RACMO22E'"
                >
                  <div class="label body1-bold">{{ $t('dataPage.chart.mod') }}.4</div>
                  <div class="value body1">— HadGEM-ES_RACMO22E</div>
                </div>
              </div>
              <div
                class="median"
                :class="{
                  selected: selectedMod === 'median'
                }"
                @click="selectedMod = 'median'"
              >
                <div class="icon">
                  <img src="/image/dataPage/dataBlock/median.svg" alt="median" />
                </div>
                <div class="body1">
                  {{ $t('dataPage.chart.median') }}
                </div>
              </div>
            </div>
          </template>

          <div class="size-up" style="cursor: not-allowed; opacity: 0.8">
            <img src="/image/dataPage/dataBlock/size-up.svg" alt="size-up" />
          </div>
        </div>
      </div>

      <table-component
          :period-season="period"
          :selected-tab="selectedTab"
          :period-years="periodYears"
          v-if="selectedDisplayType === 'map'"
      />
    </div>
  </main>
  <footer-component />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import {computed, onMounted, type Ref, ref, watch} from 'vue'
import { HeaderComponent } from '@/UI/Header'
import { FooterComponent } from '@/UI/Footer'
import TabsComponent from '@/components/dataPage/components/TabsComponent.vue'
import ChartComponent from '@/components/dataPage/components/ChartComponent/ChartComponentForAVG.vue'
import PeriodComponent from '@/components/dataPage/components/PeriodComponent.vue'
import { useGlobalStore } from '@/stores/globalStore'
import CalendarComponent from '@/components/dataPage/components/CalendarComponent.vue'
import CompareComponent from '@/components/dataPage/components/CompareComponent.vue'
import { useI18n } from 'vue-i18n'
import ChartComponentForRiverCycle from '@/components/dataPage/components/ChartComponent/ChartComponentForRiverCycle.vue'
import MapBlock from '@/components/dataPage/components/MapComponent/MapBlock.vue'
import LegendComponent from '@/components/dataPage/components/MapComponent/legend/LegendComponent.vue'
import TableComponent from "@/components/dataPage/components/MapComponent/TableComponent.vue";
import DownloadComponent from "@/components/dataPage/components/DownloadComponent.vue";
import {useCVSStore} from "@/stores/csvStore";
import {useScreenshotStore} from "@/stores/screenshotStore";

const screenshotStore = useScreenshotStore()
const captureElement = ref<HTMLElement | null>(null);

const createSreenshotFunc = async() => {
  if (captureElement.value) {
    try{
      const {default: html2canvas} = await import("html2canvas")
      const canvas = await html2canvas(captureElement.value, {
        useCORS: true,
        scale: 2,
      });
      const dataURL = canvas.toDataURL('image/'+screenshotStore.format);

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = screenshotStore.screenshotName + "." + screenshotStore.format;
      link.click();
    }
    catch (error) {
      console.log(captureElement.value)
      console.error('Screenshot error:', error);
    } finally {
      screenshotStore.screenshotName = "";
      screenshotStore.format = "";
      screenshotStore.createScreenshot = false;
    }
  }
  else {
    console.error('Screenshot error: no ref element');
  }
}

watch(() => screenshotStore.createScreenshot, (val) => {
  console.log(val)
  if(val){
    createSreenshotFunc()
  }
})

const route = useRoute()

const globalStore = useGlobalStore()

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const selectedMod = ref('median')

type SelectedTab =
  | 'discharge'
  | 'water_flow'
  | 'temperature'
  | 'precipitation'
  | 'evapotranspiration'
  | 'groundwater'
  | 'soil_water'

const selectedTab: Ref<SelectedTab> = ref(route.params.tab as SelectedTab)
const period: Ref<
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'
  | 'spring'
  | 'summer'
  | 'autumn'
  | 'winter'
  | 'annual'
> = ref('annual')

const compare: Ref<'norm' | 'rcp45' | 'rcp85' | 'null' | null> = ref(null)

// const riversForTable = computed(() => globalStore.selectedBasin === 'Всі басейни України' ? : globalStore.selectedBasinRivers)

const periodYears = ref('2021-2040')

function addItoAseinUk(inputString) {
  if (inputString === 'Всі басейни України' && currentLocale.value === 'uk') return 'усіх річкових басейнах України'
  const resultString = inputString.replace(/асейн/g, 'асейні')
  return resultString
}

function addItoAseinEn(inputString) {
  if (inputString === 'All river basins of Ukraine') return 'all river basins of Ukraine'
  return inputString
}


watch(
  () => route.params.tab,
  (newTab) => {
    selectedTab.value = newTab || 'temperature'
  },
  { immediate: true }
)

const selectedDisplayType: Ref<'average' | 'river_cycle' | 'map'> = ref('river_cycle')

const mapBlock1 = ref(null)
const mapBlock2 = ref(null)

const csvStore = useCVSStore()

const getUnits = () => {
  let pidpis = null

  if (selectedTab.value === 'temperature') {
    pidpis = '°C'
  }

  if (currentLocale.value === 'uk') {
    if (selectedTab.value === 'discharge') {
      pidpis = 'м³/с'
    }

    if (selectedTab.value === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (
        selectedTab.value !== 'discharge' &&
        selectedTab.value !== 'water_flow' &&
        selectedTab.value !== 'temperature'
    ) {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (selectedTab.value === 'discharge') {
      pidpis = 'm³/s'
    }

    if (selectedTab.value === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (
        selectedTab.value !== 'discharge' &&
        selectedTab.value !== 'water_flow' &&
        selectedTab.value !== 'temperature'
    ) {
      pidpis = 'mm'
    }
  }

  if (globalStore.units === 'relative' && selectedTab.value !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
}

onMounted(() => {
  window.scrollTo({
    top: 0,
    // behavior: 'smooth'
  });
})

const downloadCSV = () => {
  const scenario1Data = mapBlock1.value?.getCurrentDataJson()
  const scenario2Data = mapBlock2.value?.getCurrentDataJson()

  const header = [
    'Code',
    'Amount',
    'Unit',
    'Value',
    'Scenario',
    'Parameter',
    'PeriodYears',
    'Territory'
  ]
  const rows = [header]

  const addFeatures = (dataJson) => {
    if (!dataJson || !dataJson.features) return

    const scenario = dataJson.scenario

    const tab = dataJson.tab
    const periodYears = dataJson.periodYears
    const value = dataJson.units
    const territory = dataJson.territory
    const units = getUnits()

    for (const feature of dataJson.features) {
      const code = feature.properties?.code ?? ''
      const amount = feature.properties?.value

      if (value === null || value === undefined || value === '') {
        continue
      }

      const row = [
        code,
        amount,
        units,
        value,
        scenario,
        tab,
        periodYears,
        territory
      ]
      rows.push(row)
    }
  }

  addFeatures(scenario1Data)
  addFeatures(scenario2Data)

  const csvContent = rows.map(e => e.join(',')).join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', `${selectedMod.value}-${csvStore.csvName}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

watch(() => csvStore.createCSV, (val) => {
  if(selectedDisplayType.value === 'map' && val){
    downloadCSV()
    csvStore.csvName = ""
    csvStore.createCSV = false
  }
})
</script>

<style lang="scss">
.description-bottom {
  a{
    color: var(--color-blue-middle);

    &:hover{
      text-decoration: underline;
    }
  }
}

.description {
  a{
    color: var(--color-blue-middle);

    &:hover{
      text-decoration: underline;
    }
  }
}
</style>

<style lang="scss" scoped>
.hint-map{
  margin-left: 60px;
  margin-top: -10px;
  margin-bottom: -20px;
}

.flex-table {
  display: flex;
  width: 78%;
  margin: auto;
  font-size: 15px;
  margin-top: 24px;
  margin-bottom: 100px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  //padding: 10px;
}

.flex-row {
  display: flex;
  margin-bottom: 5px;
  color: var(--color-dark-gray);
  cursor: pointer;
  border-radius: 5px;

  &.selected {
    color: var(--color-black);
    background-color: var(--color-light-gray);
  }

  .label.body1-bold {
    //width: 30px;
    display: block;
  }
}

.flex-cell,
.label,
.value {
  //flex: 1;
  padding: 5px;
}

.label {
  padding-right: 0;
  text-align: right;
}

.value {
  padding-left: 4px;
  text-align: left;
}

.description-bottom {
  position: absolute;
  bottom: 32px;
}

main {
  min-height: calc(100vh - 325px - 80px);
  padding-bottom: 85px;
  background-color: var(--color-light-gray) !important;
}

.headline1 {
  color: var(--color-primary-blue);
  padding-top: 32px;
  margin-bottom: 12px;
}

.content {
  background: var(--color-white);
  border-radius: 8px 8px 0 0;
  //box-shadow: 0 1px 1px 0 #0000004d;
}

.data-block {
  padding: 24px;
  position: relative;

  .median {
    cursor: pointer;
    display: flex;
    line-height: 32px;
    position: absolute;
    right: 120px;
    bottom: 80px;
    color: var(--color-dark-gray);
    height: 20px;
    width: 120px;
    padding: 7px;
    border-radius: 5px;

    &.selected {
      color: var(--color-black);
      background-color: var(--color-light-gray);
    }

    .icon {
      margin-right: 4px;
    }
  }

  .description.label1 {
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .size-up {
    position: absolute;
    right: 24px;
    top: 560px;
    cursor: pointer;
  }
}

.map-block {
  display: flex;
  margin-left: 60px;

  .maps {
    display: flex;
    width: 1092px;
  }

  .legend {
    height: 280px;
    //width: 102px;
  }
}

.control-panel {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
  }

  .right {
    display: flex;
  }
}

.display-type {
  height: 49px;
  display: flex;
  background: var(--color-light-gray);
  //justify-content: space-around;
  border-radius: 8px;

  .button {
    cursor: pointer;
    margin: 6px;
    margin-right: 8px;
    background: var(--color-white);
    height: 36px;
    border-radius: 8px;
    padding-right: 8px;
    color: var(--color-primary-blue);

    &:hover{
      background: var(--color-light-gray);
    }

    .inner {
      display: flex;
      padding: 6px;
      border-radius: 8px;
    }

    &:last-child {
      margin-right: 6px;
    }

    .icon {
      margin-left: 2px;
      margin-right: 10px;
      margin-top: 2px;
    }

    svg.active {
      display: none;
    }

    svg.default {
      display: inline-block;
    }

    &.active {
      background: var(--color-primary-blue);
      color: var(--color-white);

      &:hover {
        background: var(--color-blue-linear2);
        color: var(--color-primary-blue);
      }

      svg.active {
        display: inline-block;
      }

      svg.default {
        display: none;
      }
    }
  }
}
</style>
