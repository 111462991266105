<template>
  <div class="map-block">
    <div class="scenario body1-bold" v-if="mapSyncStore.isCompareMap">
      <template v-if="props.scenario === 'rcp85'">RCP8.5</template>
      <template v-if="props.scenario === 'rcp45'">RCP4.5</template>
    </div>
    <div ref="mapContainer" id="map" class="map-container"></div>
  </div>
  <div class="buttons-block"></div>

<!--{{globalStore.selectedBigBasinCode}}-->
<!--{{globalStore.selectedBasinCode}}-->

  <hintComponent
    v-if="showHint && hintPolygonId && styleLoaded"
    :description="description"
    :period-season="props.periodSeason"
    :selected-model="props.selectedMod"
    :selected-period-year="props.periodYears"
    :selected-tab="props.selectedTab"
    :scenario="props.scenario"
    :selectedMod="selectedMod"
  />
  <template v-if="false">
    {{ description }}

    showHint {{ showHint }}<br />
    hintPolygonId {{ hintPolygonId }}<br />
    styleLoaded {{ styleLoaded }}
  </template>
</template>

<script lang="ts" setup>
import { watch, nextTick, onMounted, onUnmounted, ref, computed } from 'vue'
import * as maptilersdk from '@maptiler/sdk'
// import basins from './geojsons/basins.json'
// import transcordon from './geojsons/transcordon.json'
// import smallBasins from './geojsons/smallBasins_compresed.json'
// import dots from './geojsons/dots_not_many.json'
import hintRemover from './geojsons/hintRemover.json'
import legends from './legend/legends.json'
// import rivers from './geojsons/river.json'
import hintComponent from '@/components/dataPage/components/MapComponent/HintComponent.vue'
import('@maptiler/sdk/dist/maptiler-sdk.css')
import { useMapSyncStore } from '@/stores/mapSyncStore'
import axiosInstance from '@/lib/axios'
import { useGlobalStore } from '@/stores/globalStore'

const mapContainer = ref(null)
const description = ref({})

const showHint = ref(false)
const hintPolygonId = ref(null)
const styleLoaded = ref(false)
const mapRef = ref(null)
const currentDataJson = ref(null)


const globalStore = useGlobalStore()

interface Props {
  periodSeason: string
  selectedTab: string
  periodYears: string
  scenario: string
  selectedMod: string
}

const props = defineProps<Props>()

type Coordinates = [number, number]

interface Cache {
  [key: string]: Coordinates | null
}

const cache: Cache = {}
const isDotsOnMap = computed(() => props.selectedTab === 'discharge' || props.selectedTab === 'water_flow')

const findCenter = (id: string, coordinates: Coordinates[]): Coordinates | null => {
  if (cache[id]) {
    return cache[id]
  }

  if (coordinates.length === 0) {
    cache[id] = null
    return null
  }

  let x = 0
  let y = 0

  for (let i = 0; i < coordinates.length; i++) {
    const [coordX, coordY] = coordinates[i]
    x += coordX
    y += coordY
  }

  const centerX = x / coordinates.length
  const centerY = y / coordinates.length
  const result: Coordinates = [centerX, centerY]

  cache[id] = result
  return result
}

const mapSyncStore = useMapSyncStore()

const closeHint = (event) => {
  if (!event.target.closest(`#map`)) {
    showHint.value = false
  }
}

const getSmallBasinJson = async () => {
  if(globalStore.territory === 'UkraineOnly'){
    if(globalStore.selectedBigBasinCode === globalStore.selectedBasinCode){
      console.log('basins')
      return import('./geojsons/basins.json')
    }
    else {
      console.log('smallBasins_compresed')
      return import('./geojsons/smallBasins_compresed.json')
    }
  } else {
    console.log('transcordon')
    if(globalStore.selectedBigBasinCode === globalStore.selectedBasinCode){
      return import('./geojsons/transcordon.json')
    } else {
      console.log('smallBasins_compresed')
      return import('./geojsons/smallBasins_compresed.json')
    }
  }
}

const getBasinJson = async () => {
  if(globalStore.territory === 'UkraineOnly'){
    return import('./geojsons/basins.json')
  } else {
    return import('./geojsons/transcordon.json')
  }
}

onMounted(async () => {
  const map = new maptilersdk.Map({
    container: mapContainer.value,
    style: `https://api.maptiler.com/maps/5f05464a-f7e2-4374-9cdd-a30e9d35f8cf/style.json?key=BvrtwMrSBaJInDrAfqu9`,
    center: [31.1656, 48.3794],
    zoom: 4.2,
    preserveDrawingBuffer: true,
    geolocateControl: false,
    terrainControl: false,
    scaleControl: false,
    fullscreenControl: false,
    navigationControl: "bottom-right",
  })

  mapRef.value = map

  mapSyncStore.addMapForSync(map)

  map.on('load', async () => {
    map.addSource('basinsSource', {
      type: 'geojson',
      data: await getBasinJson()
    })

    map.addSource('smallBasinsSource', {
      type: 'geojson',
      data: await getSmallBasinJson()
    })

    // map.addSource('riversSource', {
    //   type: 'geojson',
    //   data: rivers
    // })

    map.addLayer({
      id: 'transcordonLayer',
      type: 'line',
      source: 'transcordonSource',
      layout: {},
      paint: {
        'line-color': '#8a9db2',
        'line-width': 3
      }
    })

    map.addLayer({
      id: 'smallBasinsLayer',
      type: 'fill',
      source: 'smallBasinsSource',
      layout: {},
      paint: {
        'fill-opacity': 0.75,
        'fill-outline-color': 'rgba(255,255,255,0.9)'
      }
    })

    map.addLayer({
      id: 'basinsLayer',
      type: 'line',
      source: 'basinsSource',
      layout: {},
      paint: {
        'line-color': '#8a9db2',
        'line-width': 3
      }
    })

    // map.addLayer({
    //   id: 'riversLayer',
    //   type: 'line',
    //   source: 'riversSource',
    //   layout: {},
    //   paint: {
    //     'line-color': 'rgba(77,151,228,0.5)',
    //     'line-width': 1
    //   }
    // })

    map.addSource('dotsSource', {
      type: 'geojson',
      data: await import('./geojsons/dots_not_many.json')
    })
    map.addLayer({
      id: 'small-polygon-outline-dots',
      type: 'line',
      source: 'smallBasinsSource',
      paint: {
        'line-color': '#8a9db2',
        'line-width': 1.5
      },
      filter: ['==', ['get', 'code'], ''] // Изначально ничего не выбирается
    })
    map.addLayer({
      id: 'big-polygon-outline',
      type: 'line',
      source: 'basinsSource',
      paint: {
        'line-color': 'rgba(17, 59, 102, 1)',
        'line-width': 3
      },
      filter: ['==', ['get', 'code'], ''] // Изначально ничего не выбирается
    })

    map.addLayer({
      id: 'dotsLayer',
      type: 'circle',
      source: 'dotsSource',
      layout: {},
      paint: {
        'circle-radius': 10,
        'circle-color': '#6C7681',
        'circle-stroke-width': 1.1,
        'circle-stroke-color': 'rgba(0,0,0,0.7)'
      }
    })

    // map.moveLayer('River');
    map.moveLayer('City labels');
    map.moveLayer('Place labels');

    const res = []
    legends[props.selectedTab][globalStore.units].forEach((el) =>
        res.unshift(el.color, el.value)
    )

    console.log('res', res.slice(0, -1))

    map.setPaintProperty('smallBasinsLayer', 'fill-color', [
      "case",
      ["==", ["get", "value"], "undefined"], "#ffffff",
      ["==", ["get", "value"], "null"], "#ffffff",
      ["step",
        // red is higher when feature.properties.temperature is higher
        ["coalesce", ["get", "value"], 0],
        ...res.slice(0, -1),
      ]])

    if (props.selectedTab === 'discharge' || props.selectedTab === 'water_flow') {
      map.setLayoutProperty('dotsLayer', 'visibility', 'visible')
      map.setLayoutProperty('smallBasinsLayer', 'visibility', 'none')
    }
    else {
      map.setLayoutProperty('dotsLayer', 'visibility', 'none')
      map.setLayoutProperty('smallBasinsLayer', 'visibility', 'visible')
    }

    map.addSource('hintRemoverSource', {
      type: 'geojson',
      data: hintRemover
    })

    map.addLayer({
      id: 'removerHintLayer',
      type: 'fill',
      source: 'hintRemoverSource',
      layout: {},
      paint: {
        'fill-color': 'rgba(7, 78, 184, 0)',
        'fill-outline-color': 'rgba(184,7,81, 0)'
      }
    })

    map.addLayer({
      id: 'small-polygon-outline',
      type: 'line',
      source: 'smallBasinsSource',
      paint: {
        'line-color': 'rgba(17, 59, 102, 1)',
        'line-width': 1.5
      },
      filter: ['==', ['get', 'code'], ''] // Изначально ничего не выбирается
    })

    map.moveLayer('dotsLayer');

    styleLoaded.value = true

    let popup = new maptilersdk.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [-235, 10]
    })

    map.on('mousemove', 'smallBasinsLayer', (e) => {
      map.getCanvas().style.cursor = 'pointer'
      console.log(e.features[0].properties)
      if (
          e.features === undefined
          ||          e.features.length === 0
          // ||          e.features[0].properties.value === null
          ||          e.features[0].properties.value === "undefined"
      ) {
        return
      }

      description.value = e.features[0].properties
      const polygonId = e.features[0].properties.id

      showHint.value = false
      nextTick(() => (showHint.value = true))
      const mouseLngLat = map.unproject([ e.point.x, e.point.y ])

      popup
          .setLngLat(mouseLngLat)
          .setHTML(`<div id='hint'></div>`)
          .addTo(map)

      nextTick(() => {
        hintPolygonId.value = polygonId
      })

      if (e.features.length > 0) {
        if (hintPolygonId.value !== null) {
          map.setFeatureState(
              {source: 'smallBasinsSource', id: hintPolygonId.value},
              {hover: false}
          )
        }

        hintPolygonId.value = +e.features[0].id
      }
    })
    map.on('mousemove', 'dotsLayer', (e) => {
      map.getCanvas().style.cursor = 'pointer'
      if (
          e.features === undefined ||
          e.features.length === 0 ||
          // e.features[0].properties.value === null ||
          e.features[0].properties.value === "undefined"
      ) {
        return
      }

      const mouseLngLat = map.unproject([ e.point.x, e.point.y ])
      description.value = e.features[0].properties
      const polygonId = e.features[0].properties.id

      showHint.value = false
      nextTick(() => (showHint.value = true))

      popup
          .setLngLat(mouseLngLat)
          .setHTML(`<div id='hint'></div>`)
          .addTo(map)

      nextTick(() => {
        hintPolygonId.value = polygonId
      })

      if (e.features.length > 0) {
        if (hintPolygonId.value !== null) {
          map.setFeatureState(
              {source: 'dotsLayer', id: hintPolygonId.value},
              {hover: false}
          )
        }

        hintPolygonId.value = +e.features[0].id
      }
    })

    map.on('mousemove', 'removerHintLayer', () => {
      map.getCanvas().style.cursor = ''

      nextTick(() => {
        if (hintPolygonId.value !== null) {
          map.setFeatureState(
              {source: 'smallBasinsSource', id: hintPolygonId.value},
              {hover: false}
          )
        }
        hintPolygonId.value = null
      })

      popup.remove()
    })
  })

  document.addEventListener('mouseover', closeHint)
})

onUnmounted(() => {
  mapSyncStore.removeMapForSync()
  document.removeEventListener('mouseover', closeHint)
})

const getParameterToDisplay = computed(() => {
  if (props.selectedTab === 'evapotranspiration') {
    if (globalStore.evaporation === 'potential') return 'potential_evapotranspiration'
    if (globalStore.evaporation === 'fact') return 'evapotranspiration'
  }

  return props.selectedTab
})

const getDataFromResponses = (el, code) => {
  if(el === null){
    console.log('data not found for code ', code, `${el}`)
    return null
  }
  let res = null
  if (getParameterToDisplay.value === 'water_flow') {
    if(el['water_flow_outlet'] === null){
      res = el['water_flow_area']
    } else {
      res = el['water_flow_outlet']
    }
  } else {
    res = el[getParameterToDisplay.value]
  }
  if(res === null){
    console.log('res is null: ', el, '\nwith code: ', code)
    return "null"
  }
  // return parseInt(res.toFixed(0))
  return res
}

const getData = async (
  code: CodeType,
  source: SourceType,
  scenario: ScenarioType,
  season: SeasonType,
  year_range: YearRangeType,
  value_type: ValueType,
) => {
  if (
    season !== 'annual' &&
    season !== 'spring' &&
    season !== 'summer' &&
    season !== 'autumn' &&
    season !== 'winter'
  ) {
    season = season.charAt(0).toUpperCase() + season.slice(1)
  }

  let area = null
  if(props.selectedTab === 'water_flow' || props.selectedTab === 'discharge'){
    area = 'whole'
  } else {
    if(globalStore.territory !== 'Transboundary' && globalStore.selectedBasinCode === globalStore.selectedBasinCode){
      area = 'within'
    } else {
      area = 'whole'
    }
  }

  return (
    await axiosInstance.get('/climate_water/', {
      params: {
        code: code,
        source: source,
        scenario: scenario,
        season: season,
        year_range: year_range,
        // year_range: globalStore.territory === 'Transboundary' ? '1991-2020' : year_range,
        value_type: value_type,
        area: area
      }
    })
  ).data
}

watch(
  [
    () => globalStore.selectedBigBasinCode,
    () => globalStore.selectedBasinCode,
    () => globalStore.units,
    () => globalStore.evaporation,
    () => props.selectedMod,
    () => props.selectedTab,
    () => props.scenario,
    () => props.periodYears,
    () => props.periodSeason,
    () => styleLoaded.value,
    () => globalStore.territory,
  ],
  async () => {
    const basinJson = await getBasinJson()
    mapRef.value?.getSource('basinsSource').setData(basinJson)

    const smallBasinJson = await getSmallBasinJson()
    mapRef.value?.getSource('smallBasinsSource').setData(smallBasinJson)

    if(!styleLoaded.value){
      console.log('Styles not loaded yet')
      return
    }
    if(globalStore.selectedBigBasinCode === '1'){
      mapRef.value?.setFilter('big-polygon-outline', ['all']);
      mapRef.value?.setFilter('small-polygon-outline', []);
    }
    else {
      mapRef.value?.setFilter('big-polygon-outline', [
        '==',
        ['get', 'code'],
        globalStore.selectedBigBasinCode
      ]);
    }

    if(globalStore.selectedBigBasinCode !== globalStore.selectedBasinCode){
        mapRef.value?.setFilter('small-polygon-outline', [
          '==',
          ['get', 'code'],
          globalStore.selectedBasinCode
        ]);
      }
    else {
        mapRef.value?.setFilter('small-polygon-outline', [
          '==',
          ['get', 'code'],
          999999
        ]);
      }

    // Create newDataJson
    let newDataJson = null
    if (isDotsOnMap.value) {

      mapRef.value?.setFilter('small-polygon-outline-dots', [
        '==',
        ['get', 'basin_code'], globalStore.selectedBigBasinCode
      ]);

      let importedData = null
      if (globalStore.selectedBigBasinCode === globalStore.selectedBasinCode) {
        importedData = (await import('./geojsons/dots_not_many.json')).default
      } else {
        importedData = (await import('./geojsons/dots_many.json')).default
      }
      newDataJson = JSON.parse(JSON.stringify(importedData)) // Создаем изменяемую копию
    } else {
      mapRef.value?.setFilter('small-polygon-outline-dots', [
        '==',
        ['get', 'basin_code'], 999999999
      ]);
      let importedData = (await getSmallBasinJson()).default
      newDataJson = JSON.parse(JSON.stringify(importedData)) // Создаем изменяемую копию
    }
    console.log('newDataJson.features1 ', newDataJson.features)
    const requests = []

    if (!isDotsOnMap.value || true){
      if (globalStore.selectedBigBasinCode !== globalStore.selectedBasinCode) {
        console.log('Polygons, not Ukr')
        for (const feature of newDataJson.features) {
          let nomerId = null
          if (feature.properties['subbasin_code'] === globalStore.selectedBigBasinCode) {
            nomerId = feature.properties['subbasin_code']
          } else if (feature.properties['basin_code'] === globalStore.selectedBigBasinCode) {
            nomerId = feature.properties['basin_code']
          }

          console.log('feature', feature)
          console.log('nomerId', nomerId)

          if (nomerId) {
            const request = getData(
                feature.properties['code'],
                props.selectedMod,
                props.scenario,
                props.periodSeason,
                props.periodYears,
                globalStore.units
            ).then((data) => {
              feature.properties["value"] = getDataFromResponses(data, feature.properties['code'])
              return feature // Optional: return modified feature if needed elsewhere
            })
            requests.push(request)
          } else {
            feature.properties["value"] = "undefined"
          }
        }
      }

      if (globalStore.selectedBigBasinCode === globalStore.selectedBasinCode){
        console.log('Polygons, Ukr')
        for (const feature of newDataJson.features) {
          console.log('feature', feature)
          const nomerId = feature.properties['code']

          if (nomerId) {
            const request = getData(
                feature.properties['code'],
                props.selectedMod,
                props.scenario,
                props.periodSeason,
                props.periodYears,
                globalStore.units
            ).then((data) => {
              feature.properties["value"] = getDataFromResponses(data, feature.properties['code'])
              return feature
            })
            requests.push(request)
          } else {
            feature.properties["value"] = "undefined"
          }
        }
      }
    }

    // if (isDotsOnMap.value) {
    //   console.log('Dots, all')
    //   for (const feature of newDataJson.features) {
    //     const nomerId = feature.properties.code
    //
    //     if (nomerId) {
    //       const request = getData(
    //           feature.properties['code'],
    //           props.selectedMod,
    //           props.scenario,
    //           props.periodSeason,
    //           props.periodYears,
    //           globalStore.units
    //       ).then((data) => {
    //         feature.properties["value"] = getDataFromResponses(data, feature.properties['code'])
    //         return feature // Optional: return modified feature if needed elsewhere
    //       })
    //       requests.push(request)
    //     } else {
    //       feature.properties["value"] = null
    //     }
    //   }
    // }

    await Promise.all(requests)
    currentDataJson.value = newDataJson

    // Сразу после присвоения данных добавляем метаданные
    currentDataJson.value.scenario = props.scenario === 'rcp85'
        ? 'RCP8.5'
        : (props.scenario === 'rcp45' ? 'RCP4.5' : props.scenario)
    currentDataJson.value.tab = props.selectedTab
    currentDataJson.value.periodYears = props.periodYears
    currentDataJson.value.units = globalStore.units
    currentDataJson.value.territory = globalStore.territory
    mapRef.value?.getSource(isDotsOnMap.value ? 'dotsSource' : 'smallBasinsSource').setData(newDataJson)

    const res = []
    legends[props.selectedTab][globalStore.units].forEach((el) =>
        res.unshift(el.value, el.color)
    )
    console.log('res1', res.slice(1, 12))
    console.log('res2', res)

    if(!isDotsOnMap.value){
      mapRef.value?.setPaintProperty('smallBasinsLayer', 'fill-color', [
        "case",
        ["==", ["get", "value"], "undefined"], "#ffffff",
        ["==", ["get", "value"], "null"], "#ffffff",
        ["step",
          // red is higher when feature.properties.temperature is higher
          ["coalesce", ["get", "value"], 0],
          ...res.slice(1, 12)
        ]])
    }
    else {
      mapRef.value?.setPaintProperty('dotsLayer', 'circle-color', [
        "case",
        ["==", ["get", "value"], "undefined"], "rgba(0, 0, 0, 0)",
        ["==", ["get", "value"], "null"], "rgb(255,255,255)",
        ["step",
          // red is higher when feature.properties.temperature is higher
          ["coalesce", ["get", "value"], 0],
          ...res.slice(1, 12)
        ]])

      mapRef.value?.setPaintProperty('dotsLayer', 'circle-stroke-width', [
        "case",
        ["==", ["get", "value"], "undefined"],
        0,
        // ["==", ["get", "value"], "null"],
        // 0,
        1.1
      ])
    }

    if (globalStore.selectedBigBasinCode === globalStore.selectedBasinCode && globalStore.selectedBasinCode !== '1') {
      mapRef.value?.setFilter('smallBasinsLayer', [
        '==',
        ['get', 'code'],
        globalStore.selectedBasinCode
      ]);
      mapRef.value?.setFilter('dotsLayer', [
        '==',
        ['get', 'code'],
        globalStore.selectedBasinCode
      ]);
    } else {
      mapRef.value?.setFilter('smallBasinsLayer', null);
      mapRef.value?.setFilter('dotsLayer', null);
    }
  },
  { immediate: true }
)

watch(
  () => props.selectedTab,
  (newTab, oldTab) => {
    if (newTab === 'discharge' || newTab === 'water_flow') {
      if (oldTab !== 'discharge' && oldTab !== 'water_flow') {
        mapRef.value?.setLayoutProperty('dotsLayer', 'visibility', 'visible')
        mapRef.value?.setLayoutProperty('smallBasinsLayer', 'visibility', 'none')
      }
      return
    }

    if (newTab !== 'discharge' && newTab !== 'water_flow') {
      if (oldTab === 'discharge' || oldTab === 'water_flow') {
        mapRef.value?.setLayoutProperty('dotsLayer', 'visibility', 'none')
        mapRef.value?.setLayoutProperty('smallBasinsLayer', 'visibility', 'visible')
      }
    }
  },
  {
    immediate: true
  }
)

const getCurrentDataJson = () => currentDataJson.value

defineExpose({
  getCurrentDataJson
})

</script>

<style scoped>
.map-block {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  position: relative;

  .scenario{
    position: absolute;
    z-index: 1;
    color: var(--color-primary-blue);
    left: 12px;
    top: 12px;
  }
}

.map-container {
  height: 404px;
  width: 100%;
  border: 1px solid #6c7681;
  border-radius: 7px;
}
</style>
