import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    hint: {
      area: "Area of the catchment basin"
    },
    utils: {
      ukraine: 'Ukraine',
      pageNotFound: 'Page not found'
    },
    header: {
      menu: {
        name: "Menu",
        is10Models: {
          name: 'Data from climate models',
          options: {
            notIs10ModelsLabel: 'Group by minimum, mean and maximum',
            is10ModelsLabel: 'Show data for 10 models'
          }
        },
        amount: {
          name: 'Amount',
          options: {
            variable: 'Change relative to the 1991-2020 climate norm.',
            absolute: 'Absolute value, units. (not for all parameters)'
          }
        },
        scenario: {
          name: 'Scenario of emissions into the atmosphere',
          nameShort: 'Scenario of emissions',
          options: {
            SSP5: 'SSP5-8.5 (RCP8.5) is a high emissions scenario',
            SSP2: 'SSP2-4.5 (RCP4.5) is a moderate emissions scenario'
          }
        },
        project: {
          name: 'Research project',
          options: {
            CMIP6: 'CMIP6 (ISIMIP3b)',
            CORDEX: 'CMIP5 (Euro-CORDEX)'
          }
        },
        area: {
          name: 'Coverage area',
          options: {
            ukraine: 'Within the territory of Ukraine',
            transboundary: 'Taking into account the transboundary part of the basins'
          }
        },
        precipitation: {
          name: 'Precipitation and temperature',
          options: {
            reanalysis: 'Climate reanalysis (ERA5-Land)',
            observation: 'Observation',
            observationWind: 'Observations corrected for wind'
          }
        },
        evaporation: {
          name: 'Evaporation',
          options: {
            actual: 'Actual',
            potentially: 'Potentially'
          }
        },
        glossary: {
          name: 'Glossary'
        },
        source: {
          name: 'Data source'
        },
        howTo: {
          name: 'How to use the platform'
        },
        reset: {
          name: 'Reset to default settings'
        },
        access: {
          name: 'Accessibility tools',
          options: {
            textSize: 'Text size',
            cancel: 'Cancel'
          }
        }
      },
      selectBasin: 'Select a basin or water management area'
    },
    mainPage: {
      logoBlock: {
        describe: 'Future water resources of Ukraine in the context of climate change',
        goToData: 'Go to the data'
      },
      infoBlock: {
        growTemperature: 'Increase in temperature over the past 15 years*',
        fallTemperature: 'Decrease in temperature over the past 15 years*',
        growPrecipitation: 'Increase in precipitation over the past 15 years*',
        fallPrecipitation: 'Decrease in precipitation over the past 15 years*',
        growVolume: 'Increase in runoff volume over the past 15 years*',
        fallVolume: 'Decrease in runoff volume over the past 15 years*'
      },
      descriptionStart:
        '*Average value',
      descriptionEnd: 'over the past 15 years (2009-2023) compared to the period 1991-2020.',
      descriptionFor: 'for',
      table: {
        riverBasin: 'River',
        theVolumeRunoff: 'Runoff',
        temperature: 'Temp,',
        fall: 'Precipitation'
      },
      aboutPlatform: {
        title:
          '<span style="font-weight: bold;">Climate & Water (C & W)</span> is a user-friendly web service that visualizes the results of modeling the impact of climate change on water resources.',
        text1:
          'C & W contains data on air temperature, precipitation, water runoff, evaporation, groundwater, and soil moisture. The algorithms use the most precise hydrological model of Ukraine, historical climate data, and an ensemble of the latest high-resolution climate models.',
        text2:
          'For user convenience, the results are aggregated by river basins and water household areas in Ukraine. Users can explore annual, seasonal, or monthly values in chart or map format. All data is freely available and can be downloaded.',
        attention: 'The web service is currently in a trial phase.'
      }
    },
    dataPage: {
      tabs: {
        discharge: 'Discharge',
        water_flow: 'Water runoff',
        temperature: 'Temperature',
        precipitation: 'Precipitation',
        evapotranspiration: 'Evaporation',
        groundwater: 'Groundwater',
        soil_water: 'Soil water'
      },
      describe: {
        climateChange: 'Climate change in',
        discharge: 'Mean discharge change',
        water_flow: 'Mean water runoff change',
        temperature: 'Mean temperature change',
        precipitation: 'Mean precipitation change',
        evapotranspiration: 'Mean evapotranspiration change',
        groundwater: 'Mean groundwater change',
        soil_water: 'Mean soil water change',
        in: 'in',
        rcp45: 'Model values under the RCP4.5 moderate emissions scenario',
        rcp45Short: 'RCP4.5 moderate emissions scenario',
        rcp85: 'Model values under the high emissions scenario RCP8.5',
        rcp85Short: 'RCP8.5 high emissions scenario'
      },
      period: {
        period: 'Period',
        avg: 'Mean value',
        riverCycle: 'Annual cycle',
        map: 'On the map of the basin',
        selectPeriod: 'Select period',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        spring: 'Spring',
        summer: 'Summer',
        autumn: 'Autumn',
        winter: 'Winter',
        annual: 'Annual',

        back: 'Back'
      },
      compare: {
        compare: 'Compare',
        default: 'Compare with',
        norm: 'Climate norm 1991-2020',
        null: 'Do not compare'
      },
      download: 'Download',
      chart: {
        mod: 'Mod',
        median: 'Median',
        description1: '<a href="/data-source">Data source</a>',
        description2: '*Relative to the baseline period 1991-2020',
        description2end: "for the outlet of the basin",
        description2household: "for the outlet of the water household area",
        norm: 'Norm for 1991-2020',
        diapazonLegend: 'Range of deviations',
        nodata: 'No data for chart',
        selectModel: 'Select a model or median:'
      },
      mapTable: {
        location: 'Location',
        za: "",
        january: 'january',
        february: 'february',
        march: 'march',
        april: 'april',
        may: 'may',
        june: 'june',
        july: 'july',
        august: 'august',
        september: 'september',
        october: 'october',
        november: 'november',
        december: 'december',
        spring: 'spring',
        summer: 'summer',
        autumn: 'autumn',
        winter: 'winter',
        annual: 'annual',
        for: 'in',
        rcp85: 'under the high emissions scenario RCP8.5',
        rcp45: 'under the moderate emissions scenario RCP4.5'
      }
    },
    pageNotFinished: {
      title1: 'This page is currently under development.',
      title2: 'We will be back soon with new information!',
      goHome: 'Home page'
    },
    footer: {
      mainPage: 'Home page',
      holosary: 'Glossary',
      howPlatform: 'How to use the platform',
      dataSources: 'Data sources',
      otherPlatforms: 'Other useful platforms:'
    },
    howToUse: {
      // Navigation titles (left menu)
      mainTitle: "How to Use the Platform",
      abstractNav: "Abstract",
      howToUseChartsAndMapsNav: "1. How to Use Charts and Maps?",
      howToUseMainMenuNav: "2. How to Use the Main Menu?",
      amountNav: "2.1 Quantity",
      scenarioEmissionsNav: "2.2 Scenario Emissions",
      researchProjectNav: "2.3 Research Project",
      coverageAreaNav: "2.4 Coverage Area",
      precipitationTemperatureNav: "2.5 Precipitation and Temperature",
      evaporationNav: "2.6 Evaporation",
      resetDefaultSettingsNav: "3. Reset to Default Settings",

      // Abstract
      abstractTitle: "",
      abstractContent:
          "<div class='small-container section'>" +
          "<p>The top panel of the platform (header) contains a <b>logo</b>, which you can click on <b>to always return to the homepage.</b> (Fig. 1)</p>" +
          '<img src="/image/infoPage/logo.jpg" alt="logo">' +
          "<b>Fig. 1.</b> Logo for navigation to the homepage" +
          "</div>" +
          "<div class='small-container section'>" +
          "<p>To view data for a basin, sub-basin, or water management area, <b>select the desired option from the dropdown menu on the right.</b> (Fig. 2)</p>" +
          '<img src="/image/infoPage/abstact-2.jpg" alt="abstact-2">' +
          "<b>Fig. 2.</b> Selection of a basin, sub-basin or water management area" +
          "</div>",

      // 1. How to Use Charts and Maps
      howToUseChartsAndMapsTitle:
          "<div class='headline4'>1. How to Use Charts and Maps?</div>",
      howToUseChartsAndMapsContent:
          "<div class='small-container section'>" +
          "<p>Bar charts show the deviation of the parameter (river discharge, water runoff, air temperature, precipitation, evaporation, groundwater, soil water) averaged over a given time period (20 years) from a long term average (1991-2020) (Fig. 1). Maps show the same changes but presented spatially, making it easy to see what is projected in different locations (Fig. 2).</p>" +
          "<p>Because some models do not even agree on the direction of future change in rainfall (whether there will be an increase or decrease), it is important not to rely just on the average or median of model results. If two models project an increase and two models project a decrease, this would be hidden in the mean. Therefore, <b>Climate & Water presents median as well as range of model results (Fig. 3).</b></p>" +
          "</div>" +
          "<img src='/image/infoPage/en_graph_1.png' alt='en_graph_1'>" +
          "<div><b>Fig. 3.</b> Climate & Water chart of the parameter</div>" +
          "<div class='section'><img src='/image/infoPage/en_graph_2.jpg' alt='ukr_graph_1'>" +
          "<div><b>Fig. 4.</b> Climate & Water options to investigate the data</div>" +
          "</div>",

      // 2. How to Use the Main Menu
      howToUseMainMenuTitle:
          "<div class='headline4'>2. How to Use the Main Menu?</div>",
      howToUseMainMenuContent: "",

      // 2.1 Quantity
      amountTitle: "<div class='label1-bold'>2.1 Quantity</div>",
      amountContent:
          "<div class='small-container'>" +
          "<p><b>Relative</b> change option displays relative change (% or 0C for temperature), a universal metric that can be applied to data not directly available in <b>Climate & Water</b>. For instance, you can use observed river discharge data for 1991–2020, apply the relative change, and estimate future discharge values.</p>" +
          "<p><b>Absolute</b> values are calculated by applying relative changes from climate projections to historical data. However, observed historical values are not available for every area or for some parameters, except for a few experimental plots (e.g., evaporation, soil water). Therefore, we used simulated values to fill data gaps and ensure consistency across all areas.</p>" +
          "<div class='section'><img src='/image/infoPage/en_amount.png' alt='ukr_graph_1'>" +
          "<div><b>Fig. 5.</b> Quantity</div>" +
          "</div>" +
          "</div>",

      // 2.2 Scenario Emissions
      scenarioEmissionsTitle: "<div class='label1-bold'>2.2 Scenario Emissions</div>",
      scenarioEmissionsContent:
          "<div class='small-container'>" +
          "<p><b>Emission scenarios</b> range from RCP 2.6 (SSP1-2.6), where humans drastically and immediately reduce global emissions, to RCP 8.5 (SSP5-8.5), where emissions continue to rise at current rates. By 2024, it is clear that RCP 2.6 is no longer achievable. Therefore, <b>Climate & Water</b> focuses on the medium emission scenario RCP 4.5 (SSP2-4.5) and the high emission scenario RCP 8.5. However, in terms of temperature change, all scenarios show similar trends until around 2050.</p>" +
          "<div class='section'><img src='/image/infoPage/en-emission.png' alt='emission'>" +
          "<div><b>Fig. 6.</b> Scenario Emissions</div>" +
          "</div>" +
          "</div>",

      // 2.3 Research Project
      researchProjectTitle: "<div class='label1-bold'>2.3 Research Project</div>",
      researchProjectContent:
          "<div class='small-container section'>" +
          "<p>There are many climate models, and they are organized into a system during so-called the <b>Coupled Model Intercomparison Project (CMIP)</b>. In <b>Climate & Water</b>, we use the most recent two phases of this project: <b>CMIP5</b> and <b>CMIP6</b>. Other projects, like <b>Euro-CORDEX</b> and <b>ISIMIP3b</b>, have improved the resolution of the global models produced in CMIP.</p>" +
          "<p><b>CMIP6</b> represents the latest climate projections and includes more factors than previous versions. However, this doesn’t guarantee that <b>CMIP6</b> predictions are more accurate than those from CMIP5. When planning, it’s important to consider the full range of possible outcomes from both generations of models.</p>" +
          "<div class='section'><img src='/image/infoPage/en-research.jpg' alt='research'>" +
          "<div><b>Fig. 7.</b> Research Project</div>" +
          "</div>" +
          "</div>",

      // 2.4 Coverage Area
      coverageAreaTitle: "<div class='label1-bold'>2.4 Coverage Area</div>",
      coverageAreaContent:
          "<div class='small-container section'>" +
          "<p>All river basins in Ukraine, except for the Southern Bug, have areas that extend into neighboring countries. To ensure accurate river discharge simulations, <b>Climate & Water</b> includes the upstream parts of these basins located abroad. However, the transnational section of the Danube River is excluded due to its large size, which makes it challenging to include in the analysis.</p>" +
          "<div class='section'><img src='/image/infoPage/en-covarage.jpg' alt='covarage'>" +
          "<div><b>Fig. 8.</b> Coverage Area</div>" +
          "</div>" +
          "</div>",

      // 2.5 Precipitation and Temperature
      precipitationTemperatureTitle:
          "<div class='label1-bold'>2.5 Precipitation and Temperature</div>",
      precipitationTemperatureContent:
          "<div class='small-container section'>" +
          "<p><b>Climate & Water</b> uses ERA5-Land as input to model water cycle for the period 1991-2024. ERA5-Land is a global land-surface climate reanalysis dataset with a resolution of 0.1°x0.1° (around 12x12 km in Ukraine). This dataset allows us to simulate areas of river basins located abroad and in the temporarily occupied territories of Ukraine. ERA5-Land represents the latest generation of climate reanalysis. While it doesn’t perfectly match ground observations, studies have found that its accuracy is acceptable for Ukraine (Osadchyi et al., 2024), especially for long-term averages (Bonchkovskyi et al., 2021).</p>" +
          "<p>In addition to reanalysis data, Climate & Water provides observed air temperature and precipitation data for watershed areas. This data is derived from refined observational time series, mapped onto a 0.1° x 0.1° grid. The source dataset is freely available on the Ukrainian Hydrometeorological Institute website.</p>" +
          "<p>The third option displays observed precipitation data corrected for wind-induced undercatch. It is well known that actual precipitation is often higher than what is measured by rain gauges. The discrepancy occurs because wind creates air vortices around the gauge opening, preventing some raindrops and especially snowflakes from entering. In Ukraine, this under-measurement accounts for 5–10% of total precipitation, depending on the method used to calculate the error (Osypov et al., 2021). We calculated the wind-induced undercatch correction using refined monthly values of observed precipitation (Osadchyi et al., 2022).</p>" +
          "<div class='section'><img src='/image/infoPage/en-temp.png' alt='ukr_graph_1'>" +
          "<div><b>Fig. 9.</b> Precipitation and Temperature</div>" +
          "</div>" +
          "</div>",

      // 2.6 Evaporation
      evaporationTitle: "<div class='label1-bold'>2.6 Evaporation</div>",
      evaporationContent:
          "<div class='small-container section'>" +
          "<p><b>Actual Evapotranspiration (ETa)</b> is what really happens. This is the amount of water that actually evaporates from the land and plants into the atmosphere. It depends on how much water is available. For example, in a dry area, actual evapotranspiration will be low because there isn’t enough water to evaporate, even if the weather is hot.</p>" +
          "<p><b>Potential Evapotranspiration (ETp)</b> is what could happen under ideal conditions. This is the amount of water that could evaporate if there were no limits on water availability. It’s like an estimate of maximum evaporation and plant water loss based on weather conditions like temperature, wind, and sunlight, assuming there’s plenty of water.</p>" +
          "<p>For example, we need this knowledge to understand water stress:</p>" +
          "<p><b>ETa</b> reflects how much water is actually available. If ETa is much lower than ETp, it indicates water stress, as plants and soil can’t meet the atmospheric demand.</p>" +
          "<p><b>ETp</b> shows how much water the atmosphere “demands” based on weather conditions. With climate change, rising temperatures often increase ETp, putting more pressure on water resources.</p>" +
          "<div class='section'><img src='/image/infoPage/en-evaporation.jpg' alt='evaporation'>" +
          "<div><b>Fig. 10.</b> Evaporation</div>" +
          "</div>" +
          "</div>",

      // 3. Reset to Default Settings
      resetDefaultSettingsTitle:
          "<div class='label1-bold'>3. Reset to Default Settings</div>",
      resetDefaultSettingsContent:
          "<div class='small-container section'>" +
          "<p>All settings have been configured by us to obtain the most accurate and complete information. However, you can change any menu item as needed. You always have the option to revert to the default settings.</p>" +
          "<div class='section'><img src='/image/infoPage/reset.png' alt='reset'>" +
          "<div><b>Fig. 11.</b> Reset to default settings</div>" +
          "</div>" +
          "</div>",
    },
    dataSource: {
      // Navigation Titles (left menu)
      mainTitle: "Data Sources",
      abstractDataNav: "Abstract",
      futureClimateDataSourcesNav:
          "1. Future climate data sources: What are global and regional climate models?",
      historicalClimateDataSourcesNav:
          "2. Historical climate data sources: What is climate reanalysis?",
      ukrainianDailyAtmPrecipTempNav:
          "3. Historical climate data sources: Ukrainian daily atmospheric precipitation and mean air temperature time series",
      historicalFutureWaterCycleNav:
          "4. Historical and future water cycle: What is a hydrological model?",
      sourcesOfInformationNav: "5. Sources of information",

      // Abstract
      abstractDataTitle: "",
      abstractContent:
          "<div class='small-container section'>" +
          "<b>Climate & Water</b> combines climate datasets with a hydrological model specifically calibrated for the river basins of Ukraine. Using daily data on precipitation, temperature, and other variables, the hydrological model simulates the water cycle for both historical periods and future scenarios, covering the years 1991 to 2100. Below, we provide a detailed overview of the data sources." +
          "</div>",

      // 1. Future climate data sources
      futureClimateDataSourcesTitle:
          "<div class='headline4 small-container'>1. Future climate data sources: What are global and regional climate models?</div>",
      futureClimateDataSourcesContent:
          "<div class='small-container'>" +
          "<p>Global Climate Models or General Circulation Models (GCMs) are the most widely used method to understand what the climate may be like in the future as a result of emissions of greenhouse gases (global warming). They are run on supercomputers that attempt to simulate the complex atmospheric and oceanic processes that determine the climate conditions we experience. Because they work at a global scale, the resolution of GCM results is typically quite coarse. Each grid cell is roughly 200 × 200 km. Regional climate models (RCMs) are applied to smaller spatial areas to produce results with greater local detail.</p>" +
          "<p>A variety of GCMs and RCMs exist around the world, housed in scientific centres concentrated in high-income countries, such as the Max Planck Institute for Meteorology (Germany), the Met Office Hadley Centre for Climate Science and Services (UK), and the National Oceanic and Atmospheric Administration (USA). GCMs and RCMs are run under different scenarios of future greenhouse gas emissions – from a best-case scenario (if extensive action is taken to reduce emissions levels) to the worst-case (if emissions keep rising with no action taken to reduce them).</p>" +
          "<p>They generate a number of possible climate futures (projections). For example, the fifth phase of the Coupled Model Intercomparison Project (CMIP5) compares findings of the different GCMs run under the same set of four different emissions scenarios, known as the Representative Concentration Pathways (RCPs).</p>" +
          "<p>" +
          "<img style='width: 680px' src='/image/infoPage/source-why.png' alt='source'>" +
          "<div><b>Fig. 1.</b> Why are there so many climate projections?</div>" +
          "</p>" +
          "<p>Given the variety of GCMs and RCMs – all run under a range of different scenarios – a wide range of climate model results are now available. Fig. 1 explains some of the reasons why there are a variety of results and ways to present them, in order to understand climate change.</p>" +
          "<p>To further refine and expand the understanding of climate change impacts, additional initiatives like <a href='https://www.euro-cordex.net/'>Euro-CORDEX</a> and <a href='https://www.isimip.org/'>ISIMIP</a> build on the results of GCMs to provide more detailed and application-focused climate projections.</p>" +
          "<p><a href='https://www.euro-cordex.net/'>Euro-CORDEX</a> is an international collaborative initiative that focuses on producing high-resolution climate projections specifically for Europe. It uses regional climate models (RCMs) to “downscale” the results of global climate models (GCMs) from CMIP phases. This process tailors the large-scale data to reflect Europe’s unique geography, capturing finer details such as mountains, coastlines, and local weather patterns. These high-resolution projections are particularly useful for applications like water management, agriculture, and urban planning, where localized information is critical.</p>" +
          "<p><a href='https://www.isimip.org/'>ISIMIP</a> (Inter-Sectoral Impact Model Intercomparison Project) takes a broader approach, linking climate projections to various sectors such as agriculture, water, health, and ecosystems. It provides a framework for comparing and harmonizing the outputs of different impact models using consistent climate data, like those from CMIP and Euro-CORDEX. This ensures that climate projections can be effectively translated into actionable insights across multiple sectors, helping policymakers and stakeholders plan for future challenges.</p>" +
          "<p class='with-back'>Climate & Water uses six climate projections from the Euro-CORDEX project (0.11°x0.11° grid or around 13x13 km for Ukraine, Table 1) and ten projections from the ISIMIP3b project (0.50°x0.50° grid or around 60x60 km for Ukraine, Table 2) as inputs to simulate the future water cycle within a hydrological model. The inputs consist of five meteorological parameters on a daily basis: air temperature (minimum, maximum, and mean), precipitation, wind speed, humidity, and solar radiation.</p>" +
          "<div><b>Table 1.</b> List of climate models from Euro-CORDEX used in Climate & Water</div>" +
          "<img src='/image/infoPage/en-table-models.png' alt='source'>" +
          "<p style='padding-top: 47px'><b>Table 2.</b> List of climate models from ISIMIP3b used in Climate & Water</p>" +
          "<img src='/image/infoPage/en-source-tab-2.png' alt='source'>" +
          "</div>",

      // 2. Historical climate data sources
      historicalClimateDataSourcesTitle:
          "<div class='headline4 small-container'>2. Historical climate data sources: What is climate reanalysis?</div>",
      historicalClimateDataSourcesContent:
          "<div class='small-container'>" +
          "<p>Observations are not evenly distributed around the globe and there are also fewer and fewer of them as we go back in time. <a href='https://climate.copernicus.eu/climate-reanalysis'>Climate reanalysis</a> can solve this problem. A reanalysis is produced by combining the output from a numerical weather prediction model (<a href='https://www.ecmwf.int/en/research/climate-reanalysis'>ECMWF’s IFS model in the case of ERA5</a>) with observations of the Earth system from across the world (including those coming from satellites, weather stations, and other instruments in the atmosphere, ocean and on land) through a complex mathematical technique known as data assimilation. The result is a globally complete and temporally consistent dataset spanning more than 80 years, enabling us to assess long-term changes in Earth’s climate.</p>" +
          "</div>" +
          "<p>" +
          "<iframe width='912' height='515' src='https://www.youtube.com/embed/FAGobvUGl24?si=AO6E9ucfio3gxWtG' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe>" +
          "</p>" +
          "<div class='small-container'>" +
          "<p>ERA5 is the latest climate reanalysis produced by ECMWF, providing hourly data on many atmospheric, land-surface and sea-state parameters together with estimates of uncertainty. ERA5 data are available in the <a href='https://cds.climate.copernicus.eu/#!/home'>Climate Data Store</a> on regular latitude-longitude grids at 0.25°x0.25° resolution, with atmospheric parameters on 37 pressure levels. <a href='https://cds.climate.copernicus.eu/datasets/reanalysis-era5-land?tab=download'>ERA5-Land</a> is a global land-surface dataset at 0.10°x0.10° (around 12x12 km for Ukraine) resolution, consistent with atmospheric data from the ERA5 reanalysis from 1950 onward.</p>" +
          "<p class='with-back'>Climate & Water uses the ERA5-Land climate reanalysis as input to simulate the water cycle for the historical period (1991–2024). The inputs consist of five meteorological parameters on a daily basis: air temperature (minimum, maximum, and mean), precipitation, wind speed, humidity, and solar radiation. Air temperature and precipitation are displayed in the 'Temperature' and 'Precipitation' tabs.</p>" +
          "</div>",

      // 3. Ukrainian daily atmospheric precipitation and mean air temperature
      ukrainianDailyAtmPrecipTempTitle:
          "<div class='headline4 small-container'>3. Historical climate data sources: Ukrainian daily atmospheric precipitation and mean air temperature time series</div>",
      ukrainianDailyAtmPrecipTempContent:
          "<div class='small-container'>" +
          "<p>In 2022, a group of Ukrainian and international researchers developed a gridded climate time series, which cover the territory of Ukraine for the period of 1946–2020 (75 years). The dataset was created by carefully processing historical climate data, including essential steps like recovering and digitizing missing values from paper records, checking data quality, making it consistent (homogenization), and mapping it onto a 0.10°x0.10° grid (around 12x12 km for Ukraine).</p>" +
          "<p>The time series of climate data are often affected by some quality control (QC) issues (missing values, outliers, errors, etc.) and artifacts (sharp breaks or gradual trends). The latter, usually referred to as climatological inhomogeneity or station signal, can arise as a result of various factors unrelated to climate change. The nature of their appearance is artificial and consists in changing measurement methods, changing the observer or equipment; changes in the environmental conditions around the stations or with the relocation of the station. Such undesirable effects were eliminated as much as possible to produce the mentioned gridded time series for Ukraine.</p>" +
          "<p>The mapping (or interpolation) algorithm was complex and used <a href='https://scholar.google.com/scholar_lookup?title=Manual%20of%20Interpolation%20Software%20MISHv1.03&publication_year=2014&author=T.%20Szentimrey&author=Z.%20Bihari'>MISH</a> (Meteorological Interpolation based on Surface Homogenized data basis) software. The MISH algorithm is a method for estimating weather data in areas where measurements might not be available. It uses ideas from spatial modeling techniques, like kriging (a way to predict values based on nearby measurements), but also includes valuable information from long-term, carefully checked weather data. To improve accuracy, MISH considers factors like the height of the land (elevation), details about the shape of the terrain (local topography), and how far the location is from the Black Sea and Sea of Azov. These factors help make better predictions about weather patterns.</p>" +
          "<p class='small-container with-back'>Climate & Water uses the <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>gridded climate time series of Ukraine</a> (0.10°x0.10° or around 12x12 km) to calculate mean air temperature and precipitation for the historical period (1991–2020). This data could be displayed as a climate norm if the ‘Observation’ option is chosen in the Main Menu (‘Precipitation and temperature’ tab).</p>" +
          "</div>",

      // 4. Historical and future water cycle
      historicalFutureWaterCycleTitle:
          "<div class='headline4 small-container'>4. Historical and future water cycle: What is a hydrological model?</div>",
      historicalFutureWaterCycleContent:
          "<div class='small-container'>" +
          "<p>With the advent of programming languages in the mid-20th century, scientists began combining physical and empirical equations that describe water cycle processes—such as evaporation, surface runoff, and infiltration—into complex algorithms. These algorithms evolved into hydrological models, which connect individual processes into a unified simulation system. Today, there are hundreds of such models used worldwide.</p>" +
          "<p>Among them, the <a href='https://swat.tamu.edu/'>Soil and Water Assessment Tool</a> (SWAT) has become the most widespread. Developed in the early 1990s by Jeff Arnold at the USDA (<a href='https://elibrary.asabe.org/abstract.asp?aid=42256'>Arnold et al., 2012</a>), SWAT started as a small project but quickly grew into a global resource. Its development is still coordinated by the USDA, ensuring consistency and innovation. The model’s success lies in several key advantages: it balances inputs and outputs effectively, comes with clear documentation, and includes helpful tools to make it user-friendly.</p>" +
          "<p>However, the greatest strength is its openness. SWAT is completely free and open-source, meaning anyone can access, study, and even modify the code. This openness has attracted a global community of scientists, who have worked together to improve the model for over 30 years. This combination of institutional support and community-driven enhancements has made SWAT a dynamic and evolving tool for hydrological research and water management.</p>" +
          "<span class='with-back'>" +
          "<p>Climate & Water uses the SWAT agro-hydrological (or agroecosystem) model to simulate key components of the water cycle, including river discharge, runoff, evaporation (such as transpiration), groundwater, and soil water. For the historical period (1991–2024), simulations are based on the ERA5-Land climate reanalysis. For the future periods (2021–2040, 2041–2060, 2061–2080, 2081–2100), as well as for the historical baseline (1991–2020), simulations are conducted individually for each climate model.</p>" +
          "<p style='margin-top: 16px'>Relative change is calculated by comparing the same climate model’s historical and future simulations. Absolute values are derived using historical simulations driven by ERA5-Land, adjusted by applying the relative change.</p>" +
          "</span>" +
          "<p>For the SWAT model of Ukraine, we began collecting data in 2018. Along with freely available global datasets—such as land cover, topography, and climate reanalysis—we developed comprehensive soil and agricultural databases to cover Ukraine and the upstream areas of its river basins located abroad. The soil database includes 325 soil profiles and 1,497 genetic horizons, each characterized by parameters like soil texture, filtration coefficient, and others illustrated in Fig. 2. The agricultural database provides yearly crop rotation data for major crops since 1980 at the oblast level, including details on fertilizer application.</p>" +
          "</div>" +
          "<p>" +
          "<img src='/image/infoPage/source-key.jpg' alt='source'>" +
          "<div><b>Fig. 2.</b> Key data inputs for the Soil and Water Assessment Tool (SWAT) model</div>" +
          "</p>" +
          "<p>" +
          "<img style='width: 910px' src='/image/infoPage/study-area.png' alt='source'>" +
          "<div><b>Fig. 3.</b> Development of the Soil and Water Assessment Tool (SWAT) model for Ukraine</div>" +
          "</p>" +
          "<div class='small-container'>" +
          "<p>The model was calibrated using river discharge observations from 52 monitoring sites (Fig. 3). Given the significant role of snowmelt processes in shaping the hydrological regime of Ukrainian rivers, snow cover depth was also calibrated using data from 61 monitoring sites across all physiographic zones. The main water cycle outputs are available on the <a href='https://landwater.uhmi.org.ua/'>Land & Water</a> web platform and can be freely downloaded.</p>" +
          "<p>A publication detailing this work is currently under review.</p>" +
          "</div>",

      // 5. Sources of information
      sourcesOfInformationTitle:
          "<div class='headline4 small-container'>5. Sources of information</div>",
      sourcesOfInformationContent:
          "<p>" +
          "<ol class='small-container'>" +
          "<li>Future Climate for Africa - Guide. 2017. <a href='http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf'>http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf</a></li>" +
          "<li>Dupar, M., with McNamara, L. and Pacha, M. (2019). Communicating climate change: A practitioner’s guide. Cape Town: Climate and Development Knowledge Network.</li>" +
          "</ol>" +
          "</p>",
    },
    glosary: {
      // Navigation Titles (left menu)
      mainTitle: "Glossary",
      abstractNav: "Abstract",
      climateChangeNav: "Climate change",
      climateModelNav: "Climate Model",
      climateNormNav: "Climate norm",
      climatePredictionNav: "Climate Prediction",
      climateProjectionNav: "Climate Projection",
      cmipNav: "Coupled Model Intercomparison Project (CMIP)",
      climateReanalysisNav: "Climate Reanalysis",
      climateMedianaNav: "Median",
      emissionsNav: "Emissions",
      emissionsScenarioNav: "Emissions Scenario",
      ensembleNav: "Ensemble",
      euroCordexNav: "EURO-CORDEX",
      globalWarmingNav: "Global warming",
      greenhouseGasNav: "Greenhouse gas (GHG) and the greenhouse effect",
      hydrologicalModelNav: "Intergovernmental Panel on Climate Change (IPCC)",
      hydroModelNav: "Hydrological model",
      sourceNav: "Sources of information",

      // Abstract
      abstractTitle: "",
      abstractContent:
          "<div class='small-container'>" +
          "Several comprehensive glossaries are available from specialized resources, such as the International Panel on Climate Change (<a href='https://www.ipcc.ch/report/ar5/wg3/glossary-english/'>Glossary IPCC</a>) or European Environmental Agency (<a href='https://www.eea.europa.eu/help/glossary#c4=10&c0=all&b_start=0'>Glossary EEA</a>). Below, we provide a glossary specifically adapted for the Climate & Water web service." +
          "</div>",

      // Climate change
      climateChangeTitle: "<div class='headline4'>Climate change</div>",
      climateChangeContent:
          "<div class='small-container'>" +
          "<p><b>Changes in Earth’s climate system and their effects on ecosystems and society.</b></p>" +
          "<p>The United Nations <a href='https://www.un.org/en/climatechange/what-is-climate-change'>defines</a> climate change as long-term shifts in temperatures and weather patterns. Human activities such as the burning of fossil fuels and deforestation have generated greenhouse gases, which have raised global average temperatures.</p>" +
          "<p>The impacts of climate change are immense, including more frequent and intense extreme weather events, rising sea levels, disrupted rainfall patterns, and melting ice in polar and mountain regions.</p>" +
          "</div>",

      // Climate Model
      climateModelTitle: "<div class='headline4'>Climate Model</div>",
      climateModelContent:
          "<div class='small-container'>" +
          "<p>A numerical representation of the <b>climate system that incorporates the physical, chemical, and biological properties</b> of its components, along with their interactions and feedback processes. Climate models vary in complexity, ranging from simplified models focusing on specific components to comprehensive systems that simulate the full climate system.</p>" +
          "<p>These include Coupled Atmosphere-Ocean-Sea Ice General Circulation Models (AOGCMs), which offer detailed simulations of interactions within the atmosphere, oceans, and ice systems. Modern climate models increasingly integrate active chemistry and biology to enhance realism.</p>" +
          "<p>They are used both as research tools to study and simulate climate processes and for practical applications such as monthly, seasonal, and inter-annual climate predictions.</p>" +
          "</div>",

      // Climate norm
      climateNormTitle: "<div class='headline4'>Climate norm</div>",
      climateNormContent:
          "<div class='small-container'>" +
          "<p>According to the World Meteorological Organization (<a href='https://community.wmo.int/en/activity-areas/climate-services/climate-products-and-initiatives/wmo-climatological-normals'>WMO</a>), a Climatological Standard Normal now refers to the most recent <b>30-year period finishing in a year ending with zero</b> (1981–2010, 1991–2020 etc.) rather than to non-overlapping 30-year-periods (1931–1960, 1961–1990 etc.).</p>" +
          "<p>However, the period from 1961 to 1990 has been retained as a standard reference period for long-term climate change assessments.</p>" +
          "<p>Climate & Water uses 1991–2020 as a baseline period based on data availability.</p>" +
          "</div>",

      // Climate Prediction
      climatePredictionTitle: "<div class='headline4'>Climate Prediction</div>",
      climatePredictionContent:
          "<div class='small-container'>" +
          "<p>A climate prediction or climate forecast is the result of an attempt to produce a most likely description or <b>estimate of the actual evolution of the climate in the future</b> (for e.g. at seasonal, inter-annual or long-term time-scales).</p>" +
          "</div>",

      // Climate Projection
      climateProjectionTitle: "<div class='headline4'>Climate Projection</div>",
      climateProjectionContent:
          "<div class='small-container'>" +
          "<p>An estimate of how the climate might change in the future, based on scenarios of greenhouse gas emissions, aerosols, or other factors. These projections use simulations from climate models and are different from predictions because they depend on assumptions about future human activities, such as economic and technological developments, which can range significantly.</p>" +
          "</div>",

      // CMIP
      cmipTitle:
          "<div class='headline4'><a style='text-decoration: none; color: inherit' href='https://wcrp-cmip.org/'>Coupled Model Intercomparison Project (CMIP) <img src='/image/infoPage/lucide_link.svg' alt='lucide_link'></a></div>",
      cmipContent:
          "<div class='small-container'>" +
          "<p>A global collaboration among climate research groups to advance the understanding of climate systems by comparing outputs from different climate models. CMIP standardizes experiments and <b>provides projections of future climate under various scenarios</b>, supporting major climate assessments like those of the Intergovernmental Panel on Climate Change (IPCC).</p>" +
          "<p><b>CMIP5</b> (launched in 2010) introduced new scenarios known as Representative Concentration Pathways (RCPs), providing insights into climate responses to different greenhouse gas concentration trajectories. It improved the representation of physical processes in models and offered extensive data for studying climate variability and long-term projections.</p>" +
          "<p><b>CMIP6</b> (initiated in 2016) builds on CMIP5 by incorporating new scenarios called Shared Socioeconomic Pathways (SSPs), which combine socio-economic factors with emissions trajectories. CMIP6 features higher resolution models, improved simulations of physical, chemical, and biological processes, and a broader set of experiments addressing key climate questions, such as the role of aerosols, land-use changes, and feedback mechanisms.</p>" +
          "</div>",

      // Climate Reanalysis
      climateReanalysisTitle: "<div class='headline4'>Climate Reanalysis</div>",
      climateReanalysisContent:
          "<div class='small-container'>" +
          "<p>A reanalysis is produced by <b>combining</b> the output from a <b>numerical weather prediction model with observations</b> of the Earth system from across the world (including those coming from satellites, weather stations, and other instruments in the atmosphere, ocean and on land) through a complex mathematical technique known as data assimilation. The result is a globally complete and temporally consistent dataset usually spanning more than 80 years, enabling us to assess long-term changes in Earth’s climate.</p>" +
          "</div>",

      climateMedianaTitle: "<div class='headline4'>Median</div>",
      climateMedianaContent: "<div class='small-container'>" +
          "<b>The median </b> is the center value of an ordered series of numbers that divides the set into two equal parts: half of the numbers are less than or equal to the median, and half are greater than or equal to the median. <b style='display: block'>For example, in the series 5,5,5,100, the median is 5.</b>" +
          "</div>",

      // Emissions
      emissionsTitle: "<div class='headline4'>Emissions</div>",
      emissionsContent:
          "<div class='small-container'>" +
          "<p>Emissions refer to gases and other <b>substances that are released into the atmosphere</b> as a result of human activities such as manufacturing, energy production and transportation. The emission of greenhouse gases like methane and carbon dioxide since the 1800s have led to global warming.</p>" +
          "</div>",

      // Emissions Scenario
      emissionsScenarioTitle: "<div class='headline4'>Emissions Scenario</div>",
      emissionsScenarioContent:
          "<div class='small-container'>" +
          "<p>A possible <b>future pathway for emissions of greenhouse gases</b> and aerosols, based on consistent assumptions about factors like population growth, economic development, and technological changes. Climate models are run under different scenarios of future greenhouse gas emissions – from a best-case scenario (if extensive action is taken to reduce emissions levels) to the worst-case (if emissions keep rising with no action taken to reduce them). They generate a number of possible climate futures (projections).</p>" +
          "</div>",

      // Ensemble
      ensembleTitle: "<div class='headline4'>Ensemble</div>",
      ensembleContent:
          "<div class='small-container'>" +
          "<p><b>A group</b> of parallel <b>model simulations</b> used for climate projections. The variation in results across ensemble members provides an estimate of the range of possible outcomes.</p>" +
          "</div>",

      // EURO-CORDEX
      euroCordexTitle:
          "<div class='headline4'><a style='text-decoration: none; color: inherit' href='https://www.euro-cordex.net/'>EURO-CORDEX <img src='/image/infoPage/lucide_link.svg' alt='lucide_link'></a></div>",
      euroCordexContent:
          "<div class='small-container'>" +
          "<p><b>European</b> branch of the international CORDEX initiative, which is a program sponsored by the World Climate Research Program (WRCP) to organize an internationally coordinated framework to produce <b>improved regional climate change projections</b> for all land regions worldwide. The CORDEX-results will serve as input for climate change impact and adaptation studies within the timeline of the Fifth Assessment Report (AR5) of the Intergovernmental Panel on Climate Change (IPCC) and beyond.</p>" +
          "</div>",

      // Global warming
      globalWarmingTitle: "<div class='headline4'>Global warming</div>",
      globalWarmingContent:
          "<div class='small-container'>" +
          "<p>Many use the terms “climate change” and “global warming” interchangeably. But while global warming refers to the <b>increasing surface temperature of the Earth</b>, “climate change” also encompasses other changes in patterns like wind and rainfall. Human activities that have increased the levels of greenhouse gases in the atmosphere are the major drivers of global warming.</p>" +
          "<p>Some <a href='https://www.theguardian.com/environment/2019/may/17/why-the-guardian-is-changing-the-language-it-uses-about-the-environment'>media outlets</a> prefer the term “global heating” over “global warming”, to better reflect the severity of the crisis.</p>" +
          "</div>",

      // Greenhouse gas
      greenhouseGasTitle:
          "<div class='headline4'>Greenhouse gas (GHG) and the greenhouse effect</div>",
      greenhouseGasContent:
          "<div class='small-container'>" +
          "<p><b>Carbon dioxide, methane, nitrogen oxide,</b> CFCs, HCFCs, and HFCs are all referred to as greenhouse gases, because their presence in the atmosphere traps the sun’s heat, warming the air around Earth like the glass walls and roof of a greenhouse. This is the greenhouse effect.</p>" +
          "<p>Human activities, like burning fossil fuels, are increasing the proportion of greenhouse gases in the atmosphere. This is strengthening the greenhouse effect, trapping more heat, and causing Earth to become warmer.</p>" +
          "</div>",

      // Intergovernmental Panel on Climate Change (IPCC)
      hydrologicalModelTitle:
          "<div class='headline4'><a style='text-decoration: none; color: inherit' href='https://www.euro-cordex.net/'>Intergovernmental Panel on Climate Change (IPCC) <img src='/image/infoPage/lucide_link.svg' alt='lucide_link'></a></div>",
      hydrologicalModelContent:
          "<div class='small-container'>" +
          "<p>The Intergovernmental Panel on Climate Change, or IPCC, is a scientific body created in 1988 by the World Meteorological Organization (WMO) and the United Nations Environment Programme. Its goal is to <b>inform governments about the latest climate science and explain what impacts climate change will likely have on the world in the coming decades</b>.</p>" +
          "<p>Currently, the IPCC has 195 member countries and brings together scientists from all over the world who contribute voluntarily to its work. The IPCC doesn’t produce original research. Instead, hundreds of scientists examine the available scientific literature and distill it into comprehensive Assessment Reports. These reports delve into what drives climate change, what its consequences on the ground may be, and how mitigation (limiting climate change) and adaptation can help protect people from the worst impacts.</p>" +
          "</div>",

      // Hydrological model
      hydroModelTitle: "<div class='headline4'>Hydrological model</div>",
      hydroModelContent:
          "<div class='small-container'>" +
          "<p>A model that <b>simulates the movement, distribution, and quality of water</b> within the hydrological cycle using mathematical representations of physical, chemical, and biological processes. These models are grounded in fundamental principles, such as conservation of mass and energy, and account for processes like precipitation, evapotranspiration, infiltration, surface runoff, and groundwater flow. Process-based hydrological models are commonly used for understanding water resources, predicting hydrological responses to changes in climate or land use, and supporting water management decisions.</p>" +
          "</div>",

      // Sources of information
      sourceTitle: "<div class='headline4'>Sources of information</div>",
      sourceContent:
          "<div class='small-container'>" +
          "<p>" +
          "<ol class='small-container'>" +
          "<li>" +
          "Dialogue Earth. Climate change glossary: the terms you need to understand, explained. <a href='https://dialogue.earth/en/climate/climate-change-glossary/'>https://dialogue.earth/en/climate/climate-change-glossary/</a>" +
          "</li>" +
          "<li>" +
          "Terminologies Used in Climate Change. Compiled by Anu Adhikari, Racchya Shah, Sony Baral, and Rajendra Khanal. 2011. International Union for Conservation of Nature and Natural Resources" +
          "</li>" +
          "</ol>" +
          "</p>" +
          "</div>",
    },
    zmist: 'Table of contents',
  },
  uk: {
    hint: {
      area: "Площа басейну водозбору"
    },
    utils: {
      ukraine: 'Україна',
      pageNotFound: 'Сторінку не знайдено'
    },
    header: {
      menu: {
        name: "Меню",
        is10Models: {
          name: 'Дані моделей клімату',
          options: {
            notIs10ModelsLabel: 'Групувати як мінімум, середнє і максимум',
            is10ModelsLabel: 'Показати дані по 10 моделям'
          }
        },
        amount: {
          name: 'Кількість',
          options: {
            variable: 'Зміна відносно кліматичної норми 1991-2020 рр.',
            absolute: 'Абсолютне значення, од. (не для всіх параметрів)'
          }
        },
        scenario: {
          name: 'Сценарій викидів в атмосферу',
          nameShort: 'Сценарій викидів',
          options: {
            SSP5: 'SSP5-8.5 (RCP8.5)— сценарій високих викидів',
            SSP2: 'SSP2-4.5 (RCP4.5) — сценарій помірних викидів'
          }
        },
        project: {
          name: 'Проєкт дослідження',
          options: {
            CMIP6: 'CMIP6 (ISIMIP3b)',
            CORDEX: 'CMIP5 (Euro-CORDEX)'
          }
        },
        area: {
          name: 'Територія охоплення',
          options: {
            ukraine: 'У межах території України',
            transboundary: 'Із урахуванням транскордонної частини басейнів'
          }
        },
        precipitation: {
          name: 'Опади та температура',
          options: {
            reanalysis: 'Кліматичний реаналіз (ERA5-Land)',
            observation: 'Спостереження',
            observationWind: 'Спостереження із поправкою на вітер'
          }
        },
        evaporation: {
          name: 'Випаровування',
          options: {
            actual: 'Фактичне',
            potentially: 'Потенційне'
          }
        },
        glossary: {
          name: 'Глосарій'
        },
        source: {
          name: 'Джерела даних'
        },
        howTo: {
          name: 'Як користуватись платформою'
        },
        reset: {
          name: 'Скинути до налаштувань за замовчуванням'
        },
        access: {
          name: 'Інструменти доступності',
          options: {
            textSize: 'Розмір тексту',
            cancel: 'Скасувати'
          }
        }
      },
      selectBasin: 'Виберіть басейн або водогосподарську ділянку'
    },
    mainPage: {
      logoBlock: {
        describe: 'Майбутні водні ресурси України в умовах зміни клімату',
        goToData: 'Перейти до даних'
      },
      infoBlock: {
        growTemperature: 'Збільшилась температура за останні 15 років*',
        fallTemperature: 'Зменшилась температура за останні 15 років*',
        growPrecipitation: 'Збільшилась кількість опадів за останні 15 років*',
        fallPrecipitation: 'Зменшилась кількість опадів за останні 15 років*',
        growVolume: 'Збільшився об’єму стоку за останні 15 років*',
        fallVolume: 'Зменшився об’єму стоку за останні 15 років*'
      },
      descriptionStart:
          '*Середнє значення',
      descriptionEnd: 'за останні 15 років (2009-2023) відносно періоду 1991-2020 рр.',
      descriptionFor: 'для',
      table: {
        riverBasin: 'Річка',
        theVolumeRunoff: "Об'єм <br /> стоку",
        temperature: 'Тем-ра, <br />',
        fall: 'Опади'
      },
      aboutPlatform: {
        title:
          '<span style="font-weight: bold;">Climate & Water (C & W)</span> - це зручний для користувача сервіс, який візуалізує результати моделювання впливу кліматичних змін на водні ресурси.',
        text1:
          'C & W містить дані про температуру повітря, опади, водний стік, випаровування, підземні води та вологість ґрунту. Алгоритми використовують гідрологічну модель України, історичні кліматичні дані та ансамбль останніх кліматичних моделей з високою роздільною здатністю.',
        text2:
          'Для зручності користувачів результати представлено на рівні річкових басейнів України та виділених водогосподарських ділянок. Користувачі можуть ознайомитися з річними, сезонними або місячними значеннями у форматі графіку або мапи. Всі дані є безкоштовними та доступні для завантаження.',
        attention: 'Вебсервіс перебуває у дослідній експлуатації.'
      }
    },
    dataPage: {
      tabs: {
        discharge: 'Витрати води',
        water_flow: 'Об’єм стоку',
        temperature: 'Температура',
        precipitation: 'Опади',
        evapotranspiration: 'Випаровування',
        groundwater: 'Підземні води',
        soil_water: 'Вологість ґрунту'
      },
      describe: {
        climateChange: 'Зміни клімату в',
        discharge: 'Середні витрати води',
        water_flow: "Середній об'єм стоку",
        temperature: 'Середня температура повітря',
        precipitation: 'Опади',
        evapotranspiration: 'Випаровування',
        groundwater: 'Шар стоку ґрунтових вод',
        soil_water: 'Запас вологи ґрунту',
        in: 'у',
        rcp45: 'Значення моделей за сценарієм помірних викидів RCP4.5',
        rcp45Short: 'RCP4.5 - Сценарій помірних викидів',
        rcp85: 'Значення моделей за сценарієм високих викидів RCP8.5',
        rcp85Short: 'RCP8.5 - Сценарій високих викидів'
      },
      period: {
        period: 'Період',
        avg: 'Середнє значення',
        riverCycle: 'Річний цикл',
        map: 'На карті басейну',
        selectPeriod: 'Оберіть період',

        january: 'Січень',
        february: 'Лютий',
        march: 'Березень',
        april: 'Квітень',
        may: 'Травень',
        june: 'Червень',
        july: 'Липень',
        august: 'Серпень',
        september: 'Вересень',
        october: 'Жовтень',
        november: 'Листопад',
        december: 'Грудень',
        spring: 'Весна',
        summer: 'Літо',
        autumn: 'Осінь',
        winter: 'Зима',
        annual: 'Рік',
        back: 'Назад'
      },
      compare: {
        compare: 'Порівняти',
        default: 'Порівняти з',
        norm: 'Кліматична норма 1991-2020 рр.',
        null: 'Не порівнювати'
      },
      download: 'Завантажити',
      chart: {
        mod: 'Мод',
        median: 'Медіана',
        description1: '<a href="/data-source">Джерела даних</a>',
        description2: 'Відносно базового періоду 1991-2020 рр.',
        description2end: ' для замикального створу басейну',
        description2household: " для замикального створу водогосподарської ділянки",
        norm: 'Норма за 1991-2020 рр.',
        diapazonLegend: 'Діапазон відхилень',
        nodata: 'Нема даних для графіка',
        selectModel: 'Оберіть модель або медіану:'
      },
      mapTable: {
        location: 'Ділянка',
        za: 'за',
        annual: 'рік',
        january: 'січень',
        february: 'лютий',
        march: 'березень',
        april: 'квітень',
        may: 'травень',
        june: 'червень',
        july: 'липень',
        august: 'серпень',
        september: 'вересень',
        october: 'жовтень',
        november: 'листопад',
        december: 'грудень',
        spring: 'весну',
        summer: 'літо',
        autumn: 'осінь',
        winter: 'зиму',
        for: 'у',
        rcp85: 'за сценарієм високих викидів RCP8.5',
        rcp45: 'за сценарієм помірних викидів RCP4.5'
      }
    },
    pageNotFinished: {
      title1: 'Ця сторінка зараз в процесі розробки.',
      title2: 'Ми скоро повернемося з новою інформацією!',
      goHome: 'На головну'
    },
    footer: {
      mainPage: 'Головна сторінка',
      holosary: 'Глосарій',
      howPlatform: 'Як користуватись платформою',
      dataSources: 'Джерела даних',
      otherPlatforms: 'Інші корисні платформи:'
    },
    howToUse: {
      mainTitle: "Як користуватися платформою",
      abstractNav: "Анотація",
      howToUseChartsAndMapsNav: "1. Як використовувати діаграми та карти.",
      howToUseMainMenuNav: "2. Як користуватися головним меню.",
      amountNav: "2.1 Кількість",
      scenarioEmissionsNav: "2.2 Сценарій викидів в атмосферу атмосферу",
      researchProjectNav: "2.3 Дослідницький проект",
      coverageAreaNav: "2.4 Територія покриття",
      precipitationTemperatureNav: "2.5 Опади та температура",
      evaporationNav: "2.6 Випаровування",
      resetDefaultSettingsNav: "3. Скидання до налаштувань за замовчуванням",

      abstractTitle: "",
      abstractContent: "<div class='small-container section'>" +
            "<p>Верхня панель платформи (шапка) містить <b>логотип</b>, на який можна натиснути, аби повернутися на <b>головну сторінку</b> (рис. 1).</p>" +
            '<img src="/image/infoPage/logo.jpg" alt="logo">' +
            '<b>Рис. 1</b> Логотип для переходу на головну сторінку' +
          "</div>" +
          "<div class='small-container section'>" +
            "<p>Щоб переглянути дані для басейну, суббасейну або водогосподарської ділянки, <b>виберіть потрібну опцію у випадаючому меню праворуч</b> (рис. 2).</p>" +
            '<img src="/image/infoPage/abstact-2.jpg" alt="abstact-2">' +
            '<b>Рис. 2</b> Вибір басейну, суббасейну або водогосподарської ділянки' +
          "</div>",

      howToUseChartsAndMapsTitle: "<div class='headline4'>1. Як користуватися діаграмами та картами</div>",
      howToUseChartsAndMapsContent: "<div class='small-container'><p>Гістограми (або “стовпчики”) показують відхилення параметрів (витрат води, об'єму стоку, температури повітря, опадів, випаровування, підземних вод, вологості ґрунту) від довгострокового середнього значення (1991-2020 рр.), усереднені за певний період часу (20 років),  (рис. 3). Такий вибір періоду пов’язаний із тим, що кліматичні зміни можна розрахувати лише за довготривалий період (20+ років). Іншими словами, наявні у світі кліматичні моделі ще не можуть передбачати погоду на 1-3 роки наперед, лише загальний тренд на 20 і більше років. Тим не менш, для найближчих 6-ти річних планів управління річковими басейнами можна орієнтуватися на період 2021-2040 рр.</p><p>Карти показують ті ж самі зміни, що і графіки, але дані поданоі просторово, що дозволяє легко оцінити, що очікується в різних місцях (рис. 4).</p>" +
            "<p>На графіках і мапах наведено групу (науковою мовою “ансамбль”) кліматичних моделей, щоби показати можливі варіанти розвитку подій. Всі моделі показують зростання температури, однак, із опадами ситуація складніша. Іноді моделі показують різний напрямок майбутніх змін кількості опадів (збільшення, зменшення чи змін немає) або інших параметрів. У цьому випадку медіана показує найбільш ймовірний напрям змін, однак, “найбільш ймовірний” не означає, що точно буде цей. .. Тому Climate & Water представляє медіану, а також діапазон результатів моделей (рис. 3).</p>" +
            "<p>Всі моделі мають однакову вагу або ймовірність. Назва моделі наводиться лише для необхідності позначення джерела даних, для практичної роботи запам’ятовувати ці назви немає необхідності.</p></div>" +
          "<img src='/image/infoPage/ukr_graph_1.jpg' alt='ukr_graph_1'> <div><b>Рис. 3</b> Графік параметра на платформі Climate & Water</div>" +
            "<div class='section'><img src='/image/infoPage/ukr_graph_2.jpg' alt='ukr_graph_1'> <div><b>Рис. 4</b> Опції для аналізу даних на платформі Climate & Water</div>" +
          "</div>",

      howToUseMainMenuTitle: "<div class='headline4'>2. Як користуватися головним меню.</div>",
      howToUseMainMenuContent: "",

      amountTitle: "<div class='label1-bold'>2.1 Кількість</div>",
      amountContent: "<div class='small-container'>" +
            "<p>Опція <b>відносна зміна</b> відображає відносну зміну (% або 0C для температури), універсальну метрику, яку можна застосувати до даних, недоступних безпосередньо у <b>Climate & Water</b>. Наприклад, ви можете використати дані спостережень витрат води за 1991-2020 роки, застосувати відносну зміну та оцінити майбутні значення витрат.</p>" +
              "<p> <b>Абсолютні</b> значення розраховуються шляхом застосування відносних змін від кліматичних проекцій до історичних даних. Однак, історичні дані спостережень не є доступними для усіх територій або для деяких параметрів, за винятком кількох експериментальних ділянок (наприклад, випаровування, вологість ґрунту). Тому ми використали змодельовані значення, щоб заповнити прогалини в даних і забезпечити узгодженість між усіма територіями.</p>" +
              "<div class='section'><img src='/image/infoPage/ukr_amount.png' alt='ukr_graph_1'> <div><b>Рис. 5</b> Кількість</div>" +
          "</div>",

      scenarioEmissionsTitle: "<div class='label1-bold'>2.2 Сценарій викидів в атмосферу</div>",
      scenarioEmissionsContent: "<div class='small-container'>" +
          "<p><b>Сценарії викидів</b> варіюються від RCP 2.6 (SSP1-2.6), де люди рішуче і миттєво скорочують глобальні викиди, до RCP 8.5 (SSP5-8.5), де викиди продовжують зростати поточними темпами. Очевидно, що до 2024 року рівень RCP 2.6 буде недосяжним.</p>" +
          "<p>Тому <b>Climate & Water</b> фокусується на сценарії помірних викидів RCP 4.5 (SSP2-4.5) та сценарії високих викидів RCP 8.5. Однак, з точки зору зміни температури, всі сценарії демонструють схожі тренди приблизно до 2050 року.</p>" +
          "<div class='section'> <img src='/image/infoPage/ukr-emission.jpg' alt='emission'> <div><b>Рис. 6</b> Сценарій викидів в атмосферу</div>" +
          "</div>",

      researchProjectTitle: "<div class='label1-bold'>2.3 Дослідницький проект</div>",
      researchProjectContent: "<div class='small-container section'>" +
          "<p>Існує багато кліматичних моделей, і вони організовані в систему в рамках проєкту <b>CMIP (Coupled Model Intercomparison Project)</b>. У <b>Climate & Water</b> ми використовуємо дві останні фази цього проєкту: <b>CMIP5</b> та <b>CMIP6</b>. Інші проєкти, такі як Euro-CORDEX та <b>ISIMIP3b</b>, покращили роздільну здатність глобальних моделей, створених в рамках CMIP.</p>" +
          "<p><b>CMIP6</b> представляє останні кліматичні проєкції і включає більше факторів, ніж попередні версії. Однак це не гарантує, що прогнози <b>CMIP6</b> є більш точними, ніж прогнози CMIP5. При плануванні важливо враховувати весь спектр можливих результатів обох поколінь моделей.</p>" +
          "<div class='section'> <img src='/image/infoPage/ukr-research.jpg' alt='research'> <div><b>Рис. 7</b> Дослідницький проект</div>" +
          "</div>",

      coverageAreaTitle: "<div class='label1-bold'>2.4 Територія охоплення</div>",
      coverageAreaContent: "<div class='small-container section'>" +
          "<p>Усі річкові басейни в Україні, за винятком Південного Бугу, мають території, що охоплюють території сусідніх країн. Для забезпечення точного моделювання витрат води, Climate & Water включає верхні частини цих басейнів, розташовані за кордоном. Однак транснаціональна ділянка річки Дунай виключена через її великий розмір, що ускладнює її врахування в аналізі.</p>" +
          "<div class='section'> <img src='/image/infoPage/ukr-covarage.jpg' alt='covarage'> <div><b>Рис. 8</b> Територія охоплення</div>" +
          "</div>",

      precipitationTemperatureTitle: "<div class='label1-bold'>2.5 Опади та температура</div>",
      precipitationTemperatureContent: "<div class='small-container section'>" +
          "<p><b>Climate & Water</b> використовує ERA5-Land як вхідні дані для моделювання водного циклу за період 1991-2024 років. ERA5-Land - це глобальний набір даних кліматичного реаналізу суходолу з роздільною здатністю 0,1°x0,1° (близько 12x12 км в Україні).</p>" +
          "<p>Цей набір даних дозволяє моделювати ділянки річкових басейнів, розташованих за кордоном та на тимчасово окупованих територіях України. ERA5-Land представляє останнє покоління кліматичного реаналізу. Хоча він не ідеально відповідає наземним спостереженням, дослідження показали, що його точність є прийнятною для України <a href='https://meetingorganizer.copernicus.org/EGU24/EGU24-15129.html'>(Осадчий та ін., 2024)</a>, особливо для довгострокових середніх значень <a href='https://www.earthdoc.org/content/papers/10.3997/2214-4609.20215K2054'>(Бончковський та ін., 2021)</a>.</p>" +
          "<p>На доповнення до даних реаналізу, <b>Climate & Water</b> надає дані <b>спостережень за температурою повітря та опадами</b> для водозбірних басейнів. Ці дані отримані з уточнених часових рядів спостережень, нанесених на сітку 0,1° x 0,1°. Набір вихідних даних знаходиться у вільному доступі на сайті <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>Українського гідрометеорологічного інституту.</a></p>" +
          "<p>Третій варіант відображає дані <b>спостережень за опадами, з поправкою на вітрове відхилення</b>. Добре відомо, що фактична кількість опадів часто вища, ніж та, що вимірюється опадомірами. Ця розбіжність виникає через те, що вітер створює повітряні вихори навколо отвору опадоміра, перешкоджаючи потраплянню частини дощових крапель і особливо сніжинок всередину. В Україні це недоврахування становить 5-10% від загальної кількості опадів, залежно від методу, який використовується для розрахунку похибки <a href='https://periodicals.karazin.ua/geoeco/article/view/18400/16743'>(Осипов та ін., 2021)</a>. Ми розрахували поправку на вітер, використовуючи уточнені місячні значення спостережуваних опадів <a href='https://linkinghub.elsevier.com/retrieve/pii/S2352340922007600'>(Осадчий та ін., 2022)</a>.</p>" +
          "<div class='section'> <img src='/image/infoPage/ukr-temp.jpg' alt='ukr_graph_1'> <div><b>Рис. 9</b> Опади та температура</div>" +
          "</div>",

      evaporationTitle: "<div class='label1-bold'>2.6 Випаровування</div>",
      evaporationContent: "<div class='small-container section'>" +
          "<p><b>Фактичне випаровування (ETa)</b> - це те, що відбувається насправді. Це кількість води, яка фактично випаровується з землі та рослин в атмосферу. Вона залежить від кількості доступної води. Наприклад, у посушливій місцевості фактичне випаровування буде низьким, оскільки води для випаровування недостатньо, навіть якщо погода спекотна.</p>" +
          "<p><b>Потенційне випаровування (ETp)</b> - це те, що могло б статися за ідеальних умов. Це кількість води, яка могла б випаруватися, якби не було обмежень доступності води. Вона являє собою оцінку максимального випаровування і втрат води рослиною на основі погодних умов, таких як температура, вітер і сонячне світло, за умови, що води достатньо.\n</p>" +
          "<p>Наприклад, нам потрібні ці дані, щоб зрозуміти водний стрес:\n</p>" +
          "<p><b>ETa</b> відображає, скільки води насправді є в наявності. Якщо ETa набагато нижче, ніж ETp, це свідчить про водний стрес, оскільки рослини і ґрунт не можуть задовольнити атмосферний попит.\n</p>" +
          "<p><b>ETp</b> показує, скільки води «вимагає» атмосфера залежно від погодних умов. Зі зміною клімату підвищення температури часто збільшує ETp, створюючи більший тиск на водні ресурси.\n</p>" +
          "<div class='section'> <img src='/image/infoPage/ukr-evaporation.jpg' alt='evaporation'> <div><b>Рис. 10</b> Випаровування</div>" +
          "</div>",

      resetDefaultSettingsTitle: "<div class='label1-bold'>3. Скинути до налаштувань за замовчуванням</div>",
      resetDefaultSettingsContent: "<div class='small-container section'>" +
          "<p>Всі налаштування були налаштовані нами для отримання найбільш точної та повної інформації. Однак ви можете змінити будь-який пункт меню за потреби. У вас завжди є можливість повернутися до налаштувань за замовчуванням.</p>" +
          "<div class='section'> <img src='/image/infoPage/reset.jpg' alt='reset'> <div><b>Рис. 11</b> Скинути до налаштувань за замовчуванням</div>" +
          "</div>",
    },
    dataSource: {
      mainTitle: "Джерела даних",
      abstractDataNav: "Анотація",
      futureClimateDataSourcesNav: "1. Джерела даних про клімат майбутнього: Що таке глобальні та регіональні кліматичні моделі.",
      historicalClimateDataSourcesNav: "2. Джерела про історичні кліматичні дані: \n" +
          "Що таке кліматичний реаналіз.",
      ukrainianDailyAtmPrecipTempNav: "3. Джерела про історичні кліматичні дані: Добові ряди атмосферних опадів та середньої температури повітря в Україні.",
      historicalFutureWaterCycleNav: "4. Історичний та майбутній кругообіг води: \n" +
          "Що таке гідрологічна модель.",
      sourcesOfInformationNav: "5. Джерела інформації.",

      abstractDataTitle: "",
      abstractContent: "<div class='small-container section'><b>Climate & Water</b> поєднує кліматичні моделі з гідрологічною моделлю, яку ми налаштували для річкових басейнів України. Окрім цього, гідрологічна модель враховує щоденні дані щодо опадів, температури та інших показників для симуляції водного циклу за історичний період. У такий спосіб охоплено період з 1991 до 2100 року. Нижче ми розглянули джерела даних більш детально.</div>",
      futureClimateDataSourcesTitle: "<div class='headline4 small-container'>1. Джерела даних про клімат майбутнього: Що таке глобальні та регіональні кліматичні моделі.</div>",
      futureClimateDataSourcesContent: "<div class='small-container'>" +
            "<p>Глобальні кліматичні моделі (ГКМ) або моделі загальної циркуляції (МЗЦ) є найбільш розповсюдженим методом для оцінки можливих змін клімату в майбутньому внаслідок викидів парникових газів (глобального потепління). Моделі запускаються на суперкомп'ютерах, які намагаються змоделювати складні атмосферні та океанічні процеси, що визначають кліматичні умови, в яких ми живемо. Оскільки ГКМ працюють на глобальному рівні, їхні результати мають досить грубу роздільну здатність. Кожна комірка сітки має розмір приблизно 200×200 км. Регіональні кліматичні моделі (РКМ) застосовуються до менших за площею територій для отримання результатів з вищою локальною деталізацією.</p>" +
            "<p>У різних частинах світу існують різноманітні ГКМ і РКМ, розміщені в наукових центрах, зосереджених у країнах з високим рівнем розвитку, таких як Інститут метеорології імені Макса Планка (Німеччина), Центр кліматичних наук та послуг Метеорологічної служби Хадлі (Великобританія) та Національне управління океанічних і атмосферних досліджень (США). ГКМ і РКМ застосовуються для різних сценаріїв майбутніх викидів парникових газів - від найкращого сценарію (у разі проведення масштабних заходів зі скорочення обсягів викидів) до найгіршого сценарію (у випадку продовження зростання викидів без жодних заходів для їх скорочення).</p>" +
            "<p>Вони генерують низку можливих кліматичних сценаріїв (проєкцій). Наприклад, на п'ятому етапі проєкту CMIP5 (Coupled Model Intercomparison Project) порівнюються результати різних ГКМ, виконаних за одним і тим же набором чотирьох різних сценаріїв викидів, відомих як репрезентативні траєкторії концентрації (RCP).</p>" +
            "<p>" +
              "<img style='width: 680px' src='/image/infoPage/source-why.png' alt='source'>" +
              "<div><b>Рис. 1</b> Чому існує так багато кліматичних проєкцій.</div>" +
            "</p>" +
            "<p>З огляду на розмаїття ГКМ і РКМ - всі вони працюють за різними сценаріями - наразі доступний широкий спектр результатів кліматичних моделей. На рис. 1 наведено деякі з причин, чому існують різні результати і способи їх представлення для розуміння змін клімату.</p>" +
            "<p>Для подальшого уточнення і розширення розуміння впливу змін клімату існують додаткові ініціативи, такі як <a href='https://www.euro-cordex.net/'>Euro-CORDEX</a> та <a href='https://www.isimip.org/'>ISIMIP</a>, які базуються на результатах ГКМ і надають більш детальні та орієнтовані на конкретні застосування кліматичні проєкції.</p>" +
            "<p><a href='https://www.euro-cordex.net/'>Euro-CORDEX</a> - це міжнародна ініціатива, спрямована на створення кліматичних проєкцій з високою роздільною здатністю спеціально для Європи. Вона використовує регіональні кліматичні моделі (РКМ) для «уточнення» результатів глобальних кліматичних моделей (ГКМ), отриманих на етапах CMIP. Цей процес адаптує великомасштабні дані до особливостей географії Європи, враховуючи найдрібніші деталі, такі як гори, берегові лінії та локальні погодні умови. Ці прогнози з високою роздільною здатністю особливо корисні для таких галузей, як управління водними ресурсами, сільське господарство та міське планування, де вкрай важлива детальна інформація.</p>" +
            "<p><a href='https://www.isimip.org/'>ISIMIP</a> (Inter-Sectoral Impact Model Intercomparison Project) застосовує ширший підхід, пов'язуючи кліматичні проєкції з різними галузями, такими як сільське господарство, водне господарство, охорона здоров'я та довкілля. Він забезпечує основу для порівняння та узгодження результатів різних моделей впливу з використанням узгоджених кліматичних даних, таких як дані CMIP та Euro-CORDEX. Це дозволяє ефективно трансформувати кліматичні проєкції в практичні рекомендації для різних галузей, допомагаючи політикам і стейкхолдерам планувати майбутні виклики.</p>" +
            "<p class='with-back'>Climate & Water використовує шість кліматичних проєкцій з проєкту Euro-CORDEX (сітка 0,11°x0,11° або близько 13x13 км для України, Таблиця 1) та десять проєкцій з проєкту ISIMIP3b (сітка 0,50°x0,50° або близько 60x60 км для України, Таблиця 2) як вхідні дані для моделювання майбутнього водного циклу в рамках гідрологічної моделі. Вхідні дані складаються з п'яти метеорологічних параметрів, що реєструються щоденно: температура повітря (мінімальна, максимальна та середня), опади, швидкість вітру, вологість та сонячна радіація.</p>" +
            "<div><b>Таблиця 1</b> Перелік кліматичних моделей із проєкту Euro-CORDEX, що використано у Climate & Water</div>" +
            "<img src='/image/infoPage/table-models.jpg' alt='source'>" +
            "<p style='padding-top: 47px'><b>Таблиця 2</b> Перелік кліматичних моделей із проєкту ISIMIP3b, що використано у Climate & Water</p>" +
            "<img src='/image/infoPage/source-tab-2.png' alt='source'>" +
          "</div>",

      historicalClimateDataSourcesTitle: "<div class='headline4 small-container'>2. Джерела про історичні кліматичні дані: Що таке кліматичний реаналіз.</div>",
      historicalClimateDataSourcesContent: "<div class='small-container'>" +
          "<p>Спостереження нерівномірно охоплюють земну кулю, а їхня кількість скорочується від сучасності до минулого. Проте, <a href='https://climate.copernicus.eu/climate-reanalysis'>кліматичний реаналіз</a> може вирішити цю проблему. Реаналіз проводиться шляхом поєднання результатів чисельного прогнозування погоди (<a href='https://www.ecmwf.int/en/research/climate-reanalysis'>модель ECMWF - IFS у випадку ERA5</a>) зі спостереженнями за Земною кулею (зокрема, із супутників, метеостанцій та інших інструментів у атмосфері, океані та на суші) за допомогою комплексного математичного підходу, відомого як «асиміляція даних». Результатом є глобально повний і узгоджений у часі набір даних, який охоплює понад 80 років, що дозволяє нам оцінювати довгострокові зміни клімату Землі.</p></div>" +
          "<p><iframe width=\"912\" height=\"515\" src=\"https://www.youtube.com/embed/FAGobvUGl24?si=AO6E9ucfio3gxWtG\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" referrerpolicy=\"strict-origin-when-cross-origin\" allowfullscreen></iframe></p>" +
          "<div class='small-container'><p>ERA5 - це найновіший <a href='https://www.ecmwf.int/en/research/climate-reanalysis'>кліматичний реаналіз, проведений ECMWF</a>, який надає погодинні дані щодо багатьох параметрів стану атмосфери, поверхні суші та моря разом з оцінками невизначеності. Дані ERA5 доступні в <a href='https://cds.climate.copernicus.eu/#!/home'>архіві кліматичних даних</a> на регулярних сітках широти-довготи з роздільною здатністю 0,25°x0,25°, з атмосферними параметрами на 37 рівнях тиску. <a href='https://cds.climate.copernicus.eu/datasets/reanalysis-era5-land?tab=download'>ERA5-Land</a> - це глобальний набір даних про стан земної поверхні з роздільною здатністю 0,10°x0,10° (близько 12x12 км для України), узгоджений з атмосферними даними, отриманими в результаті реаналізу ERA5, починаючи з 1950 року.</p>" +
          "<p class='with-back'>Climate & Water використовує кліматичний реаналіз ERA5-Land як вхідні дані для моделювання водного циклу за історичний період (1991-2024 рр.). Вхідні дані охоплюють п'ять метеорологічних параметрів щоденних спостережень: температура повітря (мінімальна, максимальна та середня), опади, швидкість вітру, вологість та сонячна радіація. Температура повітря та опади відображаються у вкладках «Температура» та «Опади».</p></div>",

      ukrainianDailyAtmPrecipTempTitle: "<div class='headline4 small-container'>3.  Джерела про історичні кліматичні дані: Добові ряди атмосферних опадів та середньої температури повітря в Україні</div>",
      ukrainianDailyAtmPrecipTempContent: "<div class='small-container'>" +
          "<p>У 2022 році група українських та міжнародних дослідників розробила <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>сіткові кліматичні часові ряди</a>, які охоплюють територію України за період 1946-2020 рр. (75 років). Набір даних було створено шляхом ретельної обробки історичних кліматичних даних, включаючи такі важливі кроки, як відновлення та оцифрування відсутніх значень з паперових записів, перевірка якості даних, забезпечення їх узгодженості (гомогенізація) та картографування на сітці 0,10°x0,10° (близько 12x12 км для України).</p>" +
          "<p>На часові ряди кліматичних даних часто впливають деякі проблеми з контролем якості (пропущені значення, відхилення, помилки тощо) та артефакти (різкі зсуви або поступові тренди). Останні, які зазвичай називають кліматологічною неоднорідністю або станційним сигналом, можуть виникати внаслідок різних факторів, не пов'язаних зі зміною клімату. Природа їх появи є штучною і полягає у зміні методів вимірювання, зміні спостерігача або обладнання; зміні природних умов навколо станцій або при переміщенні станцій. Такі небажані ефекти були максимально усунуті для отримання згаданих сіткових часових рядів для України.</p>" +
          "<p>Алгоритм картографування (або інтерполяції) був складним і використовував програмне забезпечення <a href='https://scholar.google.com/scholar_lookup?title=Manual%20of%20Interpolation%20Software%20MISHv1.03&publication_year=2014&author=T.%20Szentimrey&author=Z.%20Bihari'>MISH</a> (Meteorological Interpolation based on Surface Homogenized Data Basis). Алгоритм MISH - це метод оцінки метеорологічних даних у регіонах, де вимірювання можуть бути недоступними. У ньому використовуються ідеї з методів просторового моделювання, таких як кригінг (спосіб прогнозування значень на основі близьких вимірювань), однак він також включає цінну інформацію з довгострокових, ретельно перевірених метеорологічних даних. Для підвищення точності MISH враховує такі фактори, як висота над рівнем моря (рельєф), особливості рельєфу місцевості (місцева топографія), а також відстань від Чорного та Азовського морів. Ці фактори допомагають робити якісніші прогнози щодо погодних умов.</p>" +
          "<p class='small-container with-back'>Climate & Water використовує <a href='https://uhmi.org.ua/data_repo/ClimUAd_Ukrainian_gridded_daily'>сіткові кліматичні часові ряди України</a> (сітка 0,10°x0,10° або близько 12x12 км) для розрахунку середньої температури повітря та опадів за історичний період (1991-2020). Ці дані можуть відображатися як кліматична норма, якщо обрати опцію «Спостереження» в головному меню (вкладка «Опади та температура»).</p>" +
          "</div>",

      historicalFutureWaterCycleTitle: "<div class='headline4 small-container'>4. Історичний та майбутній кругообіг води: Що таке гідрологічна модель.</div>",
      historicalFutureWaterCycleContent: "<div class='small-container'>" +
          "<p>Із появою мов програмування, у другій половині 20 ст., фізичні та емпіричні рівняння, що описують окремі процеси водного циклу (випаровування, поверхневий стік, інфільтрація. тощо), почали об’єднувати у комплексні алгоритми, що пов’язують вказані процеси в одне ціле - так і виникли так звані гідрологічні моделі, яких на сьогодні налічуються сотні по всьому світу.</p>" +
          "<p>Найбільш розповсюдженою моделлю стала модель <a href='https://swat.tamu.edu/'>Soil and Water Assessment Tool</a> (SWAT), розроблена на початку 1990-х років Джеффом Арнольдом у Міністерстві сільського господарства США (USDA) (<a href='https://elibrary.asabe.org/abstract.asp?aid=42256'>Arnold et al., 2012</a>). SWAT починався як невеликий проект, але швидко перетворився на глобальний ресурс. Його розвиток досі координується USDA, що забезпечує послідовність та інноваційність. Успіх моделі полягає в кількох ключових перевагах: вона ефективно збалансовує вхідні та вихідні дані, забезпечена чіткою документацією та включає корисні інструменти, що роблять її зручною для користувача.</p>" +
          "<p>Однак, найвагомішою перевагою є абсолютна відкритість та доступність коду, з яким кожен може ознайомитися або завантажити та модифікувати. Ця відкритість приваблює світову спільноту науковців, які працюють разом над вдосконаленням моделі вже понад 30 років. Таке поєднання інституційної підтримки та вдосконалень, ініційованих спільнотою, зробило SWAT динамічним інструментом для гідрологічних досліджень та управління водними ресурсами.</p>" +
          "<span class='with-back'>" +
            "<p>" +
            "Climate & Water використовує агрогідрологічну (або агроекосистемну) модель SWAT для моделювання ключових компонентів водного циклу, включаючи витрати води, об’єм стоку, випаровування (наприклад, транспірацію), підземні води та вологість ґрунту. Для історичного періоду (1991-2024 рр.) моделювання ґрунтується на кліматичному реаналізі ERA5-Land. Для майбутніх періодів (2021-2040, 2041-2060, 2061-2080, 2081-2100), а також для історичного базового періоду (1991-2020) симуляції проводяться окремо для кожної кліматичної моделі." +
            "</p>" +
            "<p style='margin-top: 16px'>" +
            "Відносні зміни розраховуються шляхом порівняння історичних та майбутніх симуляцій однієї і тієї ж кліматичної моделі. Абсолютні значення отримуються за допомогою історичних симуляцій на базі ERA5-Land, скоригованих із застосуванням відносних змін." +
            "</p>" +
          "</span>" +
          "<p>Для моделі SWAT по Україні ми почали збирати дані у 2018 році. Поряд із загальнодоступними глобальними наборами даних, такими як ґрунтовий покрив, рельєф та кліматичний реаналіз, ми розробили комплексні ґрунтові та сільськогосподарські бази даних, що охоплюють Україну та верхів'я її транскордонних річкових басейнів. База даних ґрунтів включає 325 ґрунтових профілів та 1 497 генетичних горизонтів, кожен з яких характеризується такими параметрами, як гранулометричний склад ґрунту, коефіцієнт фільтрації та іншими, як показано на рис. 2. Сільськогосподарська база даних містить щорічні дані про сівозміни для основних культур з 1980 року на рівні областей, включаючи інформацію про внесення добрив.</p>" +
          "</div>" +
          "<p>" +
          "<img style='width: 912px' src='/image/infoPage/uk-source-key.jpg' alt='source'>" +
          "<div><b>Рис. 2</b> Основні вхідні дані для моделі SWAT (Soil and Water Assessment Tool)</div>" +
          "</p>" +
          "<p>" +
          "<img style='width: 910px' src='/image/infoPage/study-area.png' alt='source'>" +
          "<div><b>Рис. 3</b> Розробка моделі SWAT (Soil and Water Assessment Tool) для України</div>" +
          "</p>" +
          "<div class='small-container'>" +
          "<p>Модель була відкалібрована за даними спостережень витрат води з 52 пунктів моніторингу (рис. 3). Враховуючи значну роль процесів сніготанення у формуванні гідрологічного режиму річок України, висота снігового покриву також була відкалібрована за даними 61 пункту моніторингу в усіх фізико-географічних зонах. Основні результати дослідження кругообігу води доступні на веб-платформі <a href='https://landwater.uhmi.org.ua/'>Land & Water</a> і можуть бути завантажені у вільному доступі.</p>" +
          "<p>Наразі готується публікація з детальним описом даної роботи.</p>" +
          "</div>",

      sourcesOfInformationTitle: "<div class='headline4 small-container'>5. Використані джерела інформації.</div>",
      sourcesOfInformationContent: "<p>" +
          "<ol class='small-container'>" +
          "<li>" +
          "Future Climate for Africa - Guide. 2017. <a href='http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf'>http://www.futureclimateafrica.org/wp-content/uploads/2017/09/fcfa_gcm-guide-web.pdf</a>" +
          "</li>" +
          "<li>" +
          "Dupar, M., with McNamara, L. and Pacha, M. (2019). Communicating climate change: A practitioner’s guide. Cape Town: Climate and Development Knowledge Network." +
          "</li>" +
          "</ol>" +
          "</p>"
    },
    glosary: {
      mainTitle: "Глосарій",
      abstractNav: "Анотація",
      climateChangeNav: "Зміна клімату",
      climateModelNav: "Кліматична модель",
      climateNormNav: "Кліматична норма",
      climatePredictionNav: "Прогнозування клімату ",
      climateProjectionNav: "Кліматична проєкція",
      cmipNav: "Coupled Model Intercomparison Project (CMIP)",
      climateReanalysisNav: "Кліматичний реаналіз",
      climateMedianaNav: "Медіана",
      emissionsNav: "Викиди",
      emissionsScenarioNav: "Сценарій викидів",
      ensembleNav: "Ансамбль",
      euroCordexNav: "EURO-CORDEX",
      globalWarmingNav: "Глобальне потепління",
      greenhouseGasNav: "Парниковий газ (ПГ) та парниковий ефект",
      hydrologicalModelNav: "Міжурядова група експертів зі зміни клімату (МГЕЗК)",
      hydroModelNav: "Гідрологічна модель",
      sourceNav: "Використані джерела інформації",

      abstractTitle: "",
      abstractContent: "<div class='small-container'>Кілька вичерпних глосаріїв доступні на спеціалізованих ресурсах, таких як Міжурядова група експертів зі зміни клімату (<a href='https://www.ipcc.ch/report/ar5/wg3/glossary-english/'>Глосарій МГЕЗК</a>) або Європейська агенція довкілля (<a href='https://www.eea.europa.eu/help/glossary#c4=10&c0=all&b_start=0'>Глосарій ЄАД</a>). Нижче ми наводимо глосарій, спеціально адаптований для веб-сервісу Climate & Water.</div>",

      climateChangeTitle: "<div class='headline4'>Зміна клімату</div>",
      climateChangeContent: "<div class='small-container'>" +
          "<p><b>Зміни в кліматичній системі Землі та їхній вплив на довкілля і суспільство.</b></p>" +
          "<p>Організація Об'єднаних Націй <a href='https://www.un.org/en/climatechange/what-is-climate-change'>визначає</a> зміну клімату як довгострокові зміни температури та погодних умов. Людська діяльність, така як спалювання викопного палива та вирубка лісів, спричинила утворення парникових газів, які підвищують середню глобальну температуру.</p>" +
          "<p>Наслідки зміни клімату величезні, включаючи все частіші та інтенсивніші екстремальні погодні явища, підвищення рівня моря, порушення режиму опадів і танення льоду в полярних і гірських регіонах.</p>" +
          "</div>",

      climateModelTitle: "<div class='headline4'>Кліматична модель</div>",
      climateModelContent: "<div class='small-container'>" +
          "<p>Чисельне представлення <b>кліматичної системи, яке включає фізичні, хімічні та біологічні властивості</b> її складових, а також їхню взаємодію та процеси зворотного зв'язку. Кліматичні моделі різняться за складністю, починаючи від спрощених моделей, що фокусуються на конкретних компонентах, і закінчуючи комплексними системами, які імітують всю кліматичну систему.</p>" +
          "<p>До них відносяться моделі загальної циркуляції атмосфери й океанів (МЗЦАО), які пропонують детальні симуляції взаємодій в атмосфері, океанах і льодових системах. Сучасні кліматичні моделі все частіше інтегрують хімію та біологію для підвищення реалістичності.</p>" +
          "<p>Вони використовуються як дослідницькі інструменти для вивчення та моделювання кліматичних процесів, так і для практичного застосування, наприклад, для щомісячних, сезонних та міжрічних кліматичних прогнозів.</p>" +
          "</div>",

      climateNormTitle: "<div class='headline4'>Кліматична норма</div>",
      climateNormContent: "<div class='small-container'>" +
          "<p>За даними Всесвітньої метеорологічної організації (<a href='https://community.wmo.int/en/activity-areas/climate-services/climate-products-and-initiatives/wmo-climatological-normals'>ВМО</a>), кліматична стандартна норма наразі відноситься до останнього <b>30-річного періоду, який закінчується в рік, що дорівнює нулю</b> (1981-2010, 1991-2020 і т.д.), а не до 30-річних періодів, що не перекриваються (1931-1960, 1961-1990 і т.д.).</p>" +
          "<p>Однак період з 1961 по 1990 рік був збережений як стандартний базовий період для довгострокових оцінок зміни клімату.</p>" +
          "<p>Climate & Water використовує 1991-2020 роки як базовий період, виходячи з наявності даних.</p>" +
          "</div>",

      climatePredictionTitle: "<div class='headline4'>Прогнозування клімату</div>",
      climatePredictionContent: "<div class='small-container'>" +
          "<p>Прогнозування клімату або кліматичний прогноз - це результат спроби створити найбільш ймовірний опис або <b>оцінку фактичного розвитку клімату в майбутньому</b> (наприклад, у сезонному, міжрічному або довгостроковому часовому масштабі).</p>" +
          "</div>",

      climateProjectionTitle: "<div class='headline4'>Кліматична проєкція</div>",
      climateProjectionContent: "<div class='small-container'>" +
            "<p>Оцінка того, як клімат може змінитися в майбутньому, на основі сценаріїв викидів парникових газів, аерозолів або інших факторів. Ці проєкції використовують симуляції кліматичних моделей і різняться від прогнозів тим, що вони залежать від припущень щодо майбутньої діяльності людини, наприклад, економічного та технологічного розвитку, які можуть значно відрізнятися.</p>" +
            "</div>",

      cmipTitle: "<div class='headline4'><a style='text-decoration: none; color: inherit' href='https://wcrp-cmip.org/'>Coupled Model Intercomparison Project (CMIP) <img src='/image/infoPage/lucide_link.svg' alt='lucide_link'></a></div>",
      cmipContent: "<div class='small-container'>" +
            "<p>Глобальна колаборація між групами дослідників клімату з метою поглиблення розуміння кліматичних систем шляхом порівняння результатів, отриманих за допомогою різних кліматичних моделей. CMIP стандартизує експерименти та <b>надає проєкції майбутнього клімату за різними сценаріями</b>, підтримуючи основні кліматичні оцінки, такі як оцінки Міжурядова група експертів зі зміни клімату (IPCC).</p>" +
            "<p><b>CMIP5</b> (запущений у 2010 році) представив нові сценарії, відомі як репрезентативні траєкторії концентрації (RCP), що дають уявлення про реакцію клімату на різні траєкторії концентрацій парникових газів. Він покращив представлення фізичних процесів у моделях і запропонував широкі дані для вивчення мінливості клімату та довгострокових проєкцій.</p>" +
            "<p><b>CMIP6</b> (розпочатий у 2016 році) ґрунтується на CMIP5 шляхом включення нових сценаріїв, які називаються спільними соціально-економічними траєкторіями (SSPs), що поєднують соціально-економічні фактори з траєкторіями викидів. CMIP6 має моделі з вищою роздільною здатністю, вдосконалені симуляції фізичних, хімічних і біологічних процесів, а також ширший набір експериментів, що стосуються ключових кліматичних питань, таких як роль аерозолів, змін у землекористуванні та механізмів зворотного зв'язку.</p>" +
            "</div>",

      climateReanalysisTitle: "<div class='headline4'>Кліматичний реаналіз</div>",
      climateReanalysisContent: "<div class='small-container'>" +
          "<p>Реаналіз проводиться шляхом <b>поєднання</b> результатів <b>чисельного прогнозування погоди зі спостереженнями</b> за Земною кулею (зокрема, із супутників, метеостанцій та інших інструментів у атмосфері, океані та на суші) за допомогою комплексного математичного підходу, відомого як «асиміляція даних». Результатом є глобально повний і узгоджений у часі набір даних, який охоплює понад 80 років, що дозволяє нам оцінювати довгострокові зміни клімату Землі.</p>" +
          "</div>",

      climateMedianaTitle: "<div class='headline4'>Медіана</div>",
      climateMedianaContent: "<div class='small-container'>" +
          "<b style='font-weight: bold;'>Медіана </b> — це центральне значення впорядкованого ряду чисел, яке ділить набір на дві рівні частини: половина чисел менше або дорівнює медіані, а половина — більше або дорівнює їй. <b style='display: block'>Наприкла, в ряді 5,5,5,100. Медіана - 5.</b>" +
          "</div>",

      emissionsTitle: "<div class='headline4'>Викиди</div>",
      emissionsContent: "<div class='small-container'>" +
            "<p>Викиди - це гази та інші <b>речовини, які потрапляють в атмосферу в результаті людської діяльності</b>, такої як промисловість, енергетика та транспорт. Викиди парникових газів, таких як метан і вуглекислий газ, починаючи з 1800-х років, призвели до глобального потепління.</p>" +
            "</div>",

      emissionsScenarioTitle: "<div class='headline4'>Сценарій викидів</div>",
      emissionsScenarioContent: "<div class='small-container'>" +
            "<p>Можлива <b>майбутня динаміка викидів парникових газів</b> та аерозолів, заснована на послідовних припущеннях про такі фактори, як зростання населення, економічний розвиток та технологічні зміни. Кліматичні моделі запускаються за різними сценаріями майбутніх викидів парникових газів - від найкращого сценарію (у разі проведення масштабних заходів зі скорочення обсягів викидів) до найгіршого сценарію (у випадку продовження зростання викидів без жодних заходів для їх скорочення). Вони генерують низку можливих кліматичних сценаріїв («проєкцій»).</p>" +
            "</div>",

      ensembleTitle: "<div class='headline4'>Ансамбль</div>",
      ensembleContent: "<div class='small-container'>" +
            "<p><b>Група</b> паралельних <b>симуляцій моделей</b>, що використовуються для кліматичних проєкцій. Різниця в результатах між елементами ансамблю дає змогу оцінити діапазон можливих результатів.</p>" +
            "</div>",

      euroCordexTitle: "<div class='headline4'><a style='text-decoration: none; color: inherit' href='https://www.euro-cordex.net/'>EURO-CORDEX <img src='/image/infoPage/lucide_link.svg' alt='lucide_link'></a></div>",
      euroCordexContent: "<div class='small-container'>" +
            "<p><b>Європейське</b> відділення міжнародної ініціативи CORDEX, яка є програмою, що фінансується Всесвітньою програмою дослідження клімату (WRCP) з метою організації міжнародної координованої структури для створення <b>поліпшених регіональних проєкцій зміни клімату</b> для всіх регіонів суходолу. Результати CORDEX слугуватимуть внеском у дослідження впливу зміни клімату та адаптації до нього в рамках П'ятого оціночного звіту (AR5) Міжурядової групи експертів з питань зміни клімату (МГЕЗК) та після неї.</p>" +
            "</div>",

      globalWarmingTitle: "<div class='headline4'>Глобальне потепління</div>",
      globalWarmingContent: "<div class='small-container'>" +
            "<p>Багато хто вживає терміни «зміна клімату» і «глобальне потепління» як взаємозамінні. У той час як глобальне потепління стосується <b>підвищення температури поверхні Землі</b>, «зміна клімату» охоплює також інші зміни в погодних умовах, такі як вітер і кількість опадів. Діяльність людини, яка підвищила рівень парникових газів в атмосфері, є основною причиною глобального потепління.</p>" +
            "<p>Деякі <a href='https://www.theguardian.com/environment/2019/may/17/why-the-guardian-is-changing-the-language-it-uses-about-the-environment'>засоби масової інформації</a> віддають перевагу терміну «глобальне нагрівання», а не «глобальне потепління», щоб краще відобразити гостроту кризи.</p>" +
            "</div>",

      greenhouseGasTitle: "<div class='headline4'>Парниковий газ (ПГ) та парниковий ефект</div>",
      greenhouseGasContent: "<div class='small-container'>" +
            "<p><b>Вуглекислий газ, метан, оксид азоту</b>, фреони, гідрофреони та гідрохлорфреони називають парниковими газами, оскільки їхня наявність в атмосфері затримує сонячне тепло, нагріваючи повітря навколо земної поверхні подібно до скляних стін та даху теплиці. Це і є парниковий ефект.</p>" +
            "<p>Людська діяльність, наприклад, спалювання викопного палива, збільшує частку парникових газів в атмосфері. Це посилює парниковий ефект, затримує більше тепла і призводить до того, що Земля стає теплішою.</p>" +
            "</div>",

      hydrologicalModelTitle: "<div class='headline4'><a style='text-decoration: none; color: inherit' href='https://www.euro-cordex.net/'>Міжурядова група експертів зі зміни клімату (МГЕЗК) <img src='/image/infoPage/lucide_link.svg' alt='lucide_link'></a></div>",
      hydrologicalModelContent: "<div class='small-container'>" +
            "<p>Міжурядова група експертів зі зміни клімату (МГЕЗК) - це науковий орган, створений у 1988 році Всесвітньою метеорологічною організацією (ВМО) та Програмою ООН з навколишнього середовища. Її мета - <b> інформувати уряди про останні досягнення науки про клімат і пояснювати, який вплив матиме зміна клімату на світ у найближчі десятиліття</b>.</p>" +
            "<p>Наразі МГЕЗК налічує 195 країн-членів і об'єднує вчених з усього світу, які добровільно беруть участь у її діяльності. МГЕЗК не проводить оригінальних досліджень. Замість цього сотні вчених вивчають наявну наукову літературу і перетворюють її у всеосяжні Оціночні звіти. У цих звітах детально розглядаються причини зміни клімату, її наслідки на місцевості, а також те, як пом'якшення (обмеження зміни клімату) та адаптація до неї можуть допомогти захистити людей від найгірших наслідків.</p>" +
            "</div>",

      hydroModelTitle: "<div class='headline4'>Гідрологічна модель</div>",
      hydroModelContent: "<div class='small-container'>" +
            "<p>Модель, яка <b>імітує рух</b>, розподіл і якість води <b>в межах гідрологічного циклу</b>, використовуючи математичне представлення фізичних, хімічних і біологічних процесів. Ці моделі ґрунтуються на фундаментальних принципах, таких як збереження маси та енергії, і враховують такі процеси, як опади, випаровування, інфільтрація, поверхневий стік і підземний стік. Процес-орієнтовані гідрологічні моделі широко використовуються для розуміння водних ресурсів, прогнозування гідрологічних реакцій на зміни клімату або землекористування, а також для підтримки рішень щодо управління водними ресурсами.</p>" +
            "</div>",

      sourceTitle: "<div class='headline4'>Використані джерела інформації</div>",
      sourceContent: "<div class='small-container'>" +
            "<p>" +
            "<ol class='small-container'>" +
              "<li>" +
                "Dialogue Earth. Climate change glossary: the terms you need to understand, explained. <a href='https://dialogue.earth/en/climate/climate-change-glossary/'>https://dialogue.earth/en/climate/climate-change-glossary/</a>" +
              "</li>" +
              "<li>" +
                "Terminologies Used in Climate Change. Compiled by Anu Adhikari, Racchya Shah, Sony Baral, and Rajendra Khanal. 2011. International Union for Conservation of Nature and Natural Resources" +
              "</li>" +
            "</ol>" +
            "</p>" +
            "</div>",
    },
    zmist: 'Зміст',
  }
}

const i18n = createI18n({
  locale: 'uk',
  fallbackLocale: 'en',
  messages
})

export default i18n
