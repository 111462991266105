<template>
  <div class="dropdown-menu">
    <div class="basin-title">
      {{ $t('header.selectBasin') }}
    </div>
    <div class="sub-basins">
      <div
        class="sub-basin"
        v-for="basin in reactiveDataSubbasins"
        :key="basin.id"
        @click="openSubbasin(basin)"
      >
        <div class="title-basin">
          <div class="name">
            <span v-if="currentLocale === 'uk'">
              {{ basin.name_ua }}
            </span>
            <span v-else>
              {{ basin.name_en }}
            </span>
          </div>
          <div
            class="arrow"
            :class="{
              opened: basin.isOpened
            }"
          >
            <img v-if="basin.name_ua !== 'Всі басейни України'" src="./image/arrow-low.png" alt="arrow" />
          </div>
        </div>

        <template v-if="basin.isOpened">
          <div class="river-list" @click.stop>
            <div class="river body1-bold" @click="cbSelectSubbasins(basin)" :class="{
              active: basin.code === globalStore.selectedBasinCode
            }">
              <span v-if="currentLocale === 'uk'">
                {{ basin.name_ua }}
              </span>
                <span v-else>
                {{ basin.name_en }}
              </span>
            </div>
            <div
              class="river body1-bold"
              v-for="river in basin.rivers"
              :key="river.code"
              @click.stop="selectRiver(river, basin)"
              :class="{
                active: river.code === globalStore.selectedBasinCode
              }"
            >
              <span v-if="currentLocale === 'uk'">
                {{ river.name_ua }}
              </span>
              <span v-else>
                {{ river.name_en }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import dataSubbasins from '@/UI/Header/components/BasinDropDown/childData.json'
import { useGlobalStore } from '@/stores/globalStore'
import {nextTick, reactive, onMounted, computed} from 'vue'
import {useI18n} from "vue-i18n";
const globalStore = useGlobalStore()

const reactiveDataSubbasins = reactive(dataSubbasins.map((subbasin) => ({ ...subbasin })))
const { locale } = useI18n()
const currentLocale = computed(() => locale.value)
const emit = defineEmits(['selectedItem'])

const openSubbasin = (basin) => {
  if(basin.name_ua !== 'Всі басейни України'){
    basin.isOpened = !basin.isOpened
    return
  }
  selectSubbasin(basin)
}

const selectSubbasin = async (subbasinObject) => {
  globalStore.setSelectedBasin({name_en: subbasinObject.name_en, name_ua: subbasinObject.name_ua})
  globalStore.setSelectedBasinCode(subbasinObject.code)
  globalStore.setSelectedBasinChildren(subbasinObject.children)
  globalStore.setLogoBlockInfo(subbasinObject.logoBlock)
  globalStore.setSelectedBigBasinCode(subbasinObject.code)
  globalStore.setSelectedBasinRivers(subbasinObject.rivers)

  emit('selectedItem')
}

const cbSelectSubbasins = async (subbasinObject) => {
  selectSubbasin(subbasinObject)

  if (subbasinObject.name_ua !== 'Всі басейни України') {
    subbasinObject.isOpened = !subbasinObject.isOpened
    await nextTick()
  }
}

const selectRiver = (riverObject, subbasinObject) => {
  selectSubbasin(subbasinObject)

  globalStore.setSelectedBasin({name_en: riverObject.name_en, name_ua: riverObject.name_ua})
  globalStore.setSelectedBasinCode(riverObject.code)
}

// Fixing vue 3 reactivity
// onMounted(() => {
//   if (globalStore.firstLoad) {
//     const ukrBtn = document.getElementById('ukrBtn')
//     ukrBtn.click()
//     globalStore.firstLoad = false
//   }
// })
</script>
<style scoped lang="scss">
.arrow.opened {
  transform: rotate(0deg);
}

.dropdown-menu {
  position: absolute;
  overflow-y: scroll;
  right: 0;
  top: 60px;
  width: 548px;
  max-height: 800px;
  border-radius: 8px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-gray);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-white);
    padding: 0 5px;
  }

  .basin-title {
    padding-left: 24px;
    color: var(--color-secondary-blue);
    font-size: 15px;
    margin-top: 42px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .sub-basin {
    font-size: 20px;
    padding-right: 16px;
    padding-top: 13px;
    padding-bottom: 11px;
    font-weight: 600;
  }

  .river-list {
    background-color: var(--color-light-gray);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08) inset;
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .river {
      padding: 13px 0;
      padding-left: 24px;
      cursor: pointer;
      //font-weight: 400;
      //font-size: 15px;

      &.active {
        color: var(--color-secondary-blue);
      }

      &:hover {
        background-color: var(--color-middle-gray);
      }
    }
  }

  .title-basin {
    margin-left: 20px;
    border-radius: 8px;
    padding: 3px;
    padding-left: 10px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;

    &:hover{
      background-color: var(--color-light-gray);
    }

    img {
      margin-right: 12px;
    }

    .opened img {
      transform: rotate(90deg);
    }
  }
}
</style>
